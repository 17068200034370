import { useSearchParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableFooter,
    LinearProgress,
    Pagination,
    Typography,
    Box,
    Grid
} from '@mui/material'

import { useDebounce } from '../../shared/hooks';
import { Toolbar } from '../../shared/components';
import { Environment } from '../../shared/environments';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { IWinOnTimeConsume } from '../../shared/protocolos';
import { FormatDate, FormatDateBR, formatDateToString } from '../../shared/utils';
import { VForm, VTextField, useVForm } from '../../shared/forms';
import { WinOnTimeConsumeService } from '../../shared/services';
import { AutoCompleteWinOnTime } from './components/AutoCompleteProductsByEstablishment';

type Idata = {
    winOnTimeId: string,
    dateStart: string,
    dateEnd: string
}

export const WinOnTimeReport: React.FC = () => {

    const establishmentId = localStorage.getItem('LOGGED_ESTABLISHMENT_ID');

    const [isLoading, setIsLoading] = useState(true)
    const [searchParams, setSearchParams] = useSearchParams();
    const { formRef, save } = useVForm();

    const [rows, setRows] = useState<IWinOnTimeConsume[]>([])
    const [totalCount, setTotalCount] = useState(0)
    const { debounce } = useDebounce();


    const pagina = useMemo(() => {
        return Number(searchParams.get('pagina') || "1");
    }, [searchParams]);

    const busca = useMemo(() => {
        return searchParams.get('busca') || '';
    }, [searchParams]);

    useEffect(() => {
        setIsLoading(false);
        debounce(async () => {
            //handleTableList();
        });
    }, [busca, pagina]);

    const handleTableList = async (winOnTimeId: string, dateStart: string, dateEnd: string) => {
        await WinOnTimeConsumeService.findAllWinOnTimeConsumeByWinOnTimeIdAndDateRange("createdAt", pagina, "asc", winOnTimeId, dateStart, dateEnd, busca)
            .then((result) => {
                setIsLoading(false);
                if (result instanceof Error) {
                    alert(result.message);
                } else {
                    result.data.map((rows) => {
                        rows.createdAt = FormatDateBR((rows.createdAt as any))
                    });
                    setTotalCount(result.totalCount);
                    setRows(result.data);
                }
            });
    }

    const handlePesquisar = (dados: Idata) => {
        handleTableList(dados.winOnTimeId, FormatDate(dados.dateStart), FormatDate(dados.dateEnd))
    };


    return (
        <LayoutBaseDePagina
            barraDeFerramentas={
                <Toolbar
                    mostrarInputBusca
                    textoBotaoNovo='Pesquisar'
                    aoClicarEmNovo={save}
                    textoDaBusca={busca}
                    mostrarBotaoVoltarCarregando={false}
                    aoMudarTextoDeBusca={texto => setSearchParams({ busca: texto, pagina: '1' }, { replace: true })}
                />
            }
        >
            <Box display="flex" flexDirection="column">
                <VForm ref={formRef} onSubmit={handlePesquisar}>
                    <Box margin={1} component={Paper} variant="outlined">
                        <Grid container direction="column" padding={2} spacing={2}>
                            <Grid item>
                                <Typography variant='h6'>Pesquisar Ganhe na hora</Typography>
                            </Grid>
                            <Box display="flex" flexDirection="row" justifyItems="center" alignItems="center">
                                <Grid container item padding={2} spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={8} xl={10}>
                                        <AutoCompleteWinOnTime establismentId={establishmentId as any} />
                                    </Grid>
                                </Grid>
                                <Grid container item padding={2} spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={8} xl={10}>
                                        <VTextField
                                            fullWidth
                                            name='dateStart'
                                            label='Data de Inicio'
                                            isDate={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item padding={2} spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={8} xl={10}>
                                        <VTextField
                                            fullWidth
                                            name='dateEnd'
                                            label='Data Final'
                                            isDate={true}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>
                </VForm >
            </Box>
            <TableContainer component={Paper} variant='outlined' sx={{ m: 1, width: 'auto' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Data/Horário</TableCell>
                            <TableCell>Usuário</TableCell>
                            <TableCell>CPF</TableCell>
                            <TableCell>Valor Cheio</TableCell>
                            <TableCell>Descontos</TableCell>
                            <TableCell>Valor de Venda</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => (
                            // whiteSpace="nowrap" textOverflow="ellipsis"
                            <TableRow key={row.id} >
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" >
                                        {formatDateToString(row.createdAt?.toString() as any)}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {row.createdBy as any}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {row.cpf}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {row.finalValue + row.finalDiscount}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {row.finalDiscount}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {row.finalValue}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    {totalCount === 0 && !isLoading &&
                        (
                            <caption>{Environment.LISTAGEM_VAZIA}</caption>
                        )
                    }
                    <TableFooter>
                        {isLoading && (
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <LinearProgress variant='indeterminate' />
                                </TableCell>
                            </TableRow>
                        )}
                        {(totalCount > 0 && totalCount > Environment.LIMIT) && (
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <Pagination
                                        page={pagina}
                                        count={Math.ceil(totalCount / Environment.LIMIT)}
                                        onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
                                    />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableFooter>
                </Table>
            </TableContainer>
        </LayoutBaseDePagina>
    );

};