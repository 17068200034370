import * as React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface IAlertsProps {
    mensagem: string;
    type: number
}

export const Alerts: React.FC<IAlertsProps> = ({ mensagem, type }) => {

    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
    };


    setTimeout(handleClose, 5000);

    return (
        <>
            {type === 1 && (
                <Box display='flex' flexDirection='row' justifyContent='flex-end' marginRight={6} maxWidth="md" sx={{ mt: 20 }}>
                    <Collapse in={open} >
                        <Alert
                            severity='error'
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            <AlertTitle>Error</AlertTitle>
                            {mensagem}
                        </Alert>
                    </Collapse>
                </Box>)}
            {type === 2 && (
                <Box display='flex' flexDirection='row' justifyContent='flex-end' marginRight={6} maxWidth="md" sx={{ mt: 20 }}>
                    <Collapse in={open} >
                        <Alert
                            severity='warning'
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            <AlertTitle>Atenção</AlertTitle>
                            {mensagem}
                        </Alert>
                    </Collapse>
                </Box>)}
            {type === 3 && (
                <Box display='flex' flexDirection='row' justifyContent='flex-end' marginRight={6} maxWidth="md" sx={{ mt: 20 }}>
                    <Collapse in={open} >
                        <Alert
                            severity='info'
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            <AlertTitle>Informação</AlertTitle>
                            {mensagem}
                        </Alert>
                    </Collapse>
                </Box>)}
            {type === 4 && (
                <Box display='flex' flexDirection='row' justifyContent='flex-end' marginRight={6} maxWidth="md" sx={{ mt: 20 }}>
                    <Collapse in={open} >
                        <Alert
                            severity='success'
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            <AlertTitle>Sucesso</AlertTitle>
                            {mensagem}
                        </Alert>
                    </Collapse>
                </Box>)}
        </>
    );
}