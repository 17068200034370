import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableFooter,
    LinearProgress,
    Pagination,
    Typography
}
    from '@mui/material'

import { IPointsLog } from '../../shared/protocolos';
import { Toolbar } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { Environment } from '../../shared/environments';
import { PointsLogService } from 'shared/services';
import { useDebounce } from 'shared/hooks';
import { formatDateToString } from 'shared/utils';

export const HistoryPointsLog: React.FC = () => {

    const { pointsId = "" } = useParams<'pointsId'>();
    const { userId = "" } = useParams<'userId'>();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);


    const [searchParams, setSearchParams] = useSearchParams();
    const { debounce } = useDebounce();

    const [rows, setRows] = useState<IPointsLog[]>([])
    const [totalCount, setTotalCount] = useState(0)

    const busca = useMemo(() => {
        return searchParams.get('busca') || '';
    }, [searchParams]);

    const pagina = useMemo(() => {
        return Number(searchParams.get('pagina') || "1");
    }, [searchParams]);

    useEffect(() => {
        setIsLoading(true);
        debounce(async () => {
            handleTableList();
        });
    }, [busca, pagina]);

    const handleTableList = async () => {
        await PointsLogService.listAllByPointsIdAndUserId(pointsId, userId, "createdAt", pagina, "asc", busca)
            .then((result) => {
                setIsLoading(false);

                if (result instanceof Error) {
                    alert(result.message);
                } else {
                    setTotalCount(result.totalCount);
                    setRows(result.data);
                }
            });
    }

    const handleVoltar = () => {
        () => navigate('/home')
    };

    return (
        <>
            <LayoutBaseDePagina
                barraDeFerramentas={
                    <Toolbar
                        mostrarInputBusca
                        textoDaBusca={busca}
                        mostrarBotaoVoltar={true}
                        mostrarBotaoNovo={false}
                        aoClicarEmVoltar={() => navigate('/points')}
                        mostrarBotaoVoltarCarregando={false}
                        aoMudarTextoDeBusca={texto => setSearchParams({ busca: texto, pagina: '1' }, { replace: true })}
                    />
                }
            >
                <TableContainer component={Paper} variant='outlined' sx={{ m: 1, width: 'auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Descrição</TableCell>
                                <TableCell>Pontos</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Data de Criação</TableCell>
                                <TableCell>Resgate</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => {
                                return (
                                    <TableRow key={row.id}>
                                        <TableCell>
                                            <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" variant='inherit'>
                                                {row.description}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                                {row.point}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                                {row.status}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                                {formatDateToString(row.createdAt?.toString() as any)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                                {row.isRedemption ? "Sim" : "Não"}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                        {totalCount === 0 && !isLoading &&
                            (
                                <caption>{Environment.LISTAGEM_VAZIA}</caption>
                            )
                        }
                        <TableFooter>
                            {isLoading && (
                                <TableRow>
                                    <TableCell colSpan={3}>
                                        <LinearProgress variant='indeterminate' />
                                    </TableCell>
                                </TableRow>
                            )}
                            {(totalCount > 0 && totalCount > Environment.LIMIT) && (
                                <TableRow>
                                    <TableCell colSpan={3}>
                                        <Pagination
                                            page={pagina}
                                            count={Math.ceil(totalCount / Environment.LIMIT)}
                                            onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableFooter>
                    </Table>
                </TableContainer>
            </LayoutBaseDePagina>
        </>
    );
};