import { Box, Grid, Typography } from '@mui/material';

import { Toolbar } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';

export const TermsOfUse = () => {
    return (
        <LayoutBaseDePagina
            barraDeFerramentas={<Toolbar mostrarBotaoNovo={false} />}
        >
            <Box width='100%' display='flex' justifyContent='center' >
                <Grid container margin={2} maxWidth='lg' direction='column'>
                    <Typography variant="h4" gutterBottom>
                        Termos de Uso
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        Bem-vindo ao Qr Mania!
                    </Typography>
                    <Typography paragraph>
                        Os presentes Termos de Uso regulam o uso de nossa aplicação web. Ao acessar ou utilizar nosso serviço, você concorda com os termos e condições descritos abaixo. Por favor, leia atentamente antes de prosseguir.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        1. Aceitação dos Termos
                    </Typography>
                    <Typography paragraph>
                        Ao acessar e utilizar Qr Mania, você concorda em cumprir e estar vinculado a estes Termos de Uso e todas as leis e regulamentos aplicáveis. Se você não concorda com algum destes termos, não utilize nosso serviço.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        2. Modificações dos Termos
                    </Typography>
                    <Typography paragraph>
                        Reservamo-nos o direito de alterar ou modificar estes Termos de Uso a qualquer momento, a nosso exclusivo critério. Quaisquer alterações serão publicadas nesta página e é sua responsabilidade revisar os Termos de Uso periodicamente. Seu uso continuado da aplicação após a publicação de quaisquer mudanças constitui aceitação dessas alterações.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        3. Descrição do Serviço
                    </Typography>
                    <Typography paragraph>
                        Qr Mania oferece uma ferramenta de clube de benefícios que proporciona acesso a diversas vantagens e promoções exclusivas. Reservamo-nos o direito de modificar ou descontinuar, temporária ou permanentemente, o serviço (ou qualquer parte dele) com ou sem aviso prévio.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        4. Registro e Conta do Usuário
                    </Typography>
                    <Typography paragraph>
                        Para utilizar certos recursos de Qr Mania, você pode precisar criar uma conta. Você concorda em fornecer informações verdadeiras, precisas, atuais e completas durante o processo de registro e em manter a precisão dessas informações.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        5. Privacidade
                    </Typography>
                    <Typography paragraph>
                        Sua privacidade é importante para nós. Nossa Política de Privacidade explica como coletamos, usamos e divulgamos informações pessoais. Ao utilizar Qr Mania, você concorda com a coleta e uso de informações conforme descrito em nossa Política de Privacidade.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        6. Conduta do Usuário
                    </Typography>
                    <Typography paragraph>
                        Você concorda em não utilizar Qr Mania para:
                    </Typography>
                    <Box component="ul" pl={2}>
                        <Typography component="li">
                            Violar qualquer lei local, estadual, nacional ou internacional aplicável;
                        </Typography>
                        <Typography component="li">
                            Carregar ou transmitir qualquer conteúdo que seja ilegal, prejudicial, ameaçador, abusivo, difamatório, vulgar, obsceno ou de outra forma censurável;
                        </Typography>
                        <Typography component="li">
                            Infringir os direitos de propriedade intelectual de terceiros;
                        </Typography>
                        <Typography component="li">
                            Enviar publicidade não solicitada ou não autorizada, "spam", "correntes" ou qualquer outra forma de solicitação.
                        </Typography>
                    </Box>

                    <Typography variant="h6" gutterBottom>
                        7. Propriedade Intelectual
                    </Typography>
                    <Typography paragraph>
                        Todo o conteúdo incluído em Qr Mania, como texto, gráficos, logotipos, ícones, imagens, clipes de áudio, downloads digitais e software, é propriedade de [Nome da Empresa] ou de seus fornecedores de conteúdo e protegido pelas leis de direitos autorais internacionais.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        8. Isenção de Garantias
                    </Typography>
                    <Typography paragraph>
                        Qr Mania é fornecido "como está" e "conforme disponível". Não garantimos que o serviço será ininterrupto, seguro ou livre de erros. Não oferecemos garantias de qualquer tipo, expressas ou implícitas, sobre a operação de Qr Mania ou as informações, conteúdos, materiais ou produtos incluídos.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        9. Limitação de Responsabilidade
                    </Typography>
                    <Typography paragraph>
                        Em nenhuma hipótese, [Nome da Empresa], seus diretores, funcionários ou agentes serão responsáveis por quaisquer danos diretos, indiretos, incidentais, especiais, consequenciais ou exemplares (incluindo, sem limitação, danos por perda de lucros, dados ou outras perdas intangíveis) decorrentes do uso ou da incapacidade de uso de Qr Mania.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        10. Indenização
                    </Typography>
                    <Typography paragraph>
                        Você concorda em indenizar e isentar [Nome da Empresa], seus diretores, funcionários e agentes de qualquer reivindicação ou demanda, incluindo honorários advocatícios razoáveis, feita por qualquer terceiro devido ou decorrente do seu uso de Qr Mania, violação destes Termos de Uso ou violação de qualquer lei ou direitos de terceiros.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        11. Rescisão
                    </Typography>
                    <Typography paragraph>
                        Reservamo-nos o direito de encerrar ou suspender sua conta e acesso a Qr Mania, sem aviso prévio ou responsabilidade, por qualquer motivo, incluindo, sem limitação, se você violar estes Termos de Uso.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        12. Disposições Gerais
                    </Typography>
                    <Typography paragraph>
                        Estes Termos de Uso constituem o acordo integral entre você e [Nome da Empresa] em relação ao uso de Qr Mania. Se qualquer disposição destes Termos de Uso for considerada inválida por um tribunal de jurisdição competente, a invalidade de tal disposição não afetará a validade das demais disposições destes Termos de Uso, que permanecerão em pleno vigor e efeito.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        13. Lei Aplicável
                    </Typography>
                    <Typography paragraph>
                        Estes Termos de Uso serão regidos e interpretados de acordo com as leis do [País/Estado], sem consideração aos seus conflitos de princípios legais.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        Contato
                    </Typography>
                    <Typography paragraph>
                        Se você tiver alguma dúvida sobre estes Termos de Uso, entre em contato conosco pelo e-mail: [seuemail@exemplo.com].
                    </Typography>

                    <Typography paragraph>
                        Ao clicar em "Aceito", você reconhece que leu, compreendeu e concorda em ficar vinculado a estes Termos de Uso.
                    </Typography>
                </Grid>
            </Box>
        </LayoutBaseDePagina>
    )
};
