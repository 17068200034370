
import { useEffect, useState } from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';

import { Toolbar } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';
import {
    ProductService,
    WinOnTimeService,
    PointsService,
    BenefitRedemptionService
} from '../../shared/services';

export const Dashboard = () => {

    const [isLoadingProduct, setIsLoadingProduct] = useState(true);
    const [isLoadingWinOnTime, setIsLoadingWinOnTime] = useState(true);
    const [isLoadingUserPoints, setIsLoadingUserPoints] = useState(true);
    const [isLoadingRedemptions, setIsLoadingRedemptions] = useState(true);

    const [totalCountProduct, setTotalCountProduct] = useState(0);
    const [totalCountWinOnTime, setTotalCountWinOnTime] = useState(0);
    const [totalCountUserPoints, setTotalCountUserPoints] = useState(0);
    const [totalCountRedemptions, setTotalCountRedemptions] = useState(0);

    const establishmentId = localStorage.getItem('LOGGED_ESTABLISHMENT_ID');

    useEffect(() => {
        setIsLoadingProduct(true);
        setIsLoadingWinOnTime(true);
        setIsLoadingUserPoints(true);
        setIsLoadingRedemptions(true);

        ProductService.listAllByEstablishment("id", 1, "asc", establishmentId ? establishmentId : "", "")
            .then((result) => {
                setIsLoadingProduct(false);
                if (result instanceof Error) {
                    alert(result.message);
                } else {
                    setTotalCountProduct(result.totalCount);
                }
            });
        WinOnTimeService.listAll("id", 1, "asc", establishmentId ? establishmentId : "", "")
            .then((result) => {
                setIsLoadingWinOnTime(false);
                if (result instanceof Error) {
                    alert(result.message);
                } else {
                    setTotalCountWinOnTime(result.totalCount);
                }
            });
        PointsService.listAllByEstablishment(establishmentId ? establishmentId : "", "id", 1, "asc", "")
            .then((result) => {
                setIsLoadingUserPoints(false);
                if (result instanceof Error) {
                    alert(result.message);
                } else {
                    setTotalCountUserPoints(result.totalCount);
                }
            });
        BenefitRedemptionService.listAllByEstablishmentId(establishmentId ? establishmentId : "", "id", 1, "asc", "")
            .then((result) => {
                setIsLoadingRedemptions(false);
                if (result instanceof Error) {
                    alert(result.message);
                } else {
                    setTotalCountRedemptions(result.totalCount);
                }
            });
    }, []);


    return (
        <LayoutBaseDePagina
            barraDeFerramentas={<Toolbar mostrarBotaoNovo={false} />}
        >
            <Box width='100%' display='flex'>
                <Grid container margin={2}>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                            <Card>
                                <CardContent>
                                    <Typography variant='h5' align='center'>
                                        Total de Produtos
                                    </Typography>
                                    <Box padding={6} display='flex' justifyContent='center' alignItems='center'>
                                        {!isLoadingProduct && (
                                            <Typography variant='h1'>
                                                {totalCountProduct}
                                            </Typography>
                                        )}
                                        {isLoadingProduct && (
                                            <Typography variant='h6'>
                                                Carregando...
                                            </Typography>
                                        )}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                            <Card>
                                <CardContent>
                                    <Typography variant='h5' align='center'>
                                        Total de Ganhe na Hora
                                    </Typography>
                                    <Box padding={6} display='flex' justifyContent='center' alignItems='center'>
                                        {!isLoadingWinOnTime && (
                                            <Typography variant='h1'>
                                                {totalCountWinOnTime}
                                            </Typography>
                                        )}
                                        {isLoadingWinOnTime && (
                                            <Typography variant='h6'>
                                                Carregando...
                                            </Typography>
                                        )}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                            <Card>
                                <CardContent>
                                    <Typography variant='h5' align='center'>
                                        Total de Usuários com Pontos
                                    </Typography>
                                    <Box padding={6} display='flex' justifyContent='center' alignItems='center'>
                                        {!isLoadingUserPoints && (
                                            <Typography variant='h1'>
                                                {totalCountUserPoints}
                                            </Typography>
                                        )}
                                        {isLoadingUserPoints && (
                                            <Typography variant='h6'>
                                                Carregando...
                                            </Typography>
                                        )}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                            <Card>
                                <CardContent>
                                    <Typography variant='h5' align='center'>
                                        Total de Resgates
                                    </Typography>
                                    <Box padding={6} display='flex' justifyContent='center' alignItems='center'>
                                        {!isLoadingRedemptions && (
                                            <Typography variant='h1'>
                                                {totalCountRedemptions}
                                            </Typography>
                                        )}
                                        {isLoadingRedemptions && (
                                            <Typography variant='h6'>
                                                Carregando...
                                            </Typography>
                                        )}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>
                </Grid>
            </Box>
        </LayoutBaseDePagina>
    );
};