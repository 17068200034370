import { useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, CircularProgress, TextField, Typography } from '@mui/material';
import * as yup from 'yup';

import { useAuthContext } from '../../contexts';
import { UserService } from '../../services';

const loginSchema = yup.object().shape({
    username: yup.string().email().required(),
    password: yup.string().required().min(3),
});

interface ILoginProps {
    children: React.ReactNode;
}
export const Login: React.FC<ILoginProps> = ({ children }) => {

    const { isAuthenticated, login, setAccessToken } = useAuthContext();
    const [isForgetPassword, setForgetPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [emailForgetPassword, setEmailForgetPassword] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');

    const handleSubmit = async () => {
        setIsLoading(true);
        const token = await login(username, password);
        setAccessToken(token as any);
        setUsername("");
        setPassword("");
        setIsLoading(false);
    };

    const handleForgotPassword = async () => {
        await UserService.sendEmailForgotPassword(emailForgetPassword)
        setForgetPassword(false);
    };

    if (isAuthenticated) return (
        <>{children}</>
    );

    if (isForgetPassword) return (
        <>
            <Box width='100vw' height='100vh' display='flex' alignItems='center' justifyContent='center'>
                <Card>
                    <CardContent>
                        <Box display='flex' flexDirection='column' gap={2} width={250}>
                            <Typography variant='h6' align='center'>Recuperação de Senha</Typography>
                            <TextField
                                fullWidth
                                type='username'
                                label='Email'
                                disabled={isLoading}
                                error={!!usernameError}
                                helperText={usernameError}
                                onKeyDown={() => setUsernameError('')}
                                onChange={e => setEmailForgetPassword(e.target.value)}
                            />
                        </Box>
                    </CardContent>
                    <CardActions>
                        <Box width='100%' display='flex' justifyContent='center' marginBottom={2}>
                            <Button
                                variant='contained'
                                disabled={isLoading}
                                onClick={handleForgotPassword}
                                endIcon={isLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : undefined}
                            >
                                Enviar
                            </Button>
                        </Box>
                    </CardActions>

                </Card>
            </Box >
        </>
    );

    return (
        <Box width='100vw' height='100vh' display='flex' alignItems='center' justifyContent='center'>
            <Card>
                <CardContent>
                    <Box display='flex' flexDirection='column' gap={2} width={250}>
                        <Typography variant='h6' align='center'>Identifique-se</Typography>

                        <TextField
                            fullWidth
                            type='username'
                            label='Email'
                            value={username}
                            disabled={isLoading}
                            error={!!usernameError}
                            helperText={usernameError}
                            onKeyDown={() => setUsernameError('')}
                            onChange={e => setUsername(e.target.value)}
                        />

                        <TextField
                            fullWidth
                            label='Senha'
                            type='password'
                            value={password}
                            disabled={isLoading}
                            error={!!passwordError}
                            helperText={passwordError}
                            onKeyDown={() => setPasswordError('')}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </Box>
                </CardContent>
                <CardActions>
                    <Box width='100%' display='flex' justifyContent='center'>
                        <Button
                            variant='contained'
                            disabled={isLoading}
                            onClick={handleSubmit}
                            endIcon={isLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : undefined}
                        >
                            Entrar
                        </Button>
                    </Box>
                </CardActions>
                <Box width='100%' display='flex' justifyContent='center' marginTop={1} marginBottom={2}>
                    <Typography onClick={() => { setForgetPassword(true) }}>
                        Esqueceu a senha?
                    </Typography>
                </Box>
            </Card>
        </Box>
    );
};