import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, LinearProgress, Paper, Typography } from '@mui/material';

import { IProduct } from '../../shared/protocolos';
import { DetailTool } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { useAuthContext } from '../../shared/contexts/AuthContext'
import { ProductService } from '../../shared/services/product/productService';
import { VTextField, VForm, useVForm, IVFormErrors } from '../../shared/forms';
import { AutoCompleteTypeOfSale } from './componets/AutoCompleteTypesOfSale';
import { formatInputNumber } from 'shared/utils/formatNumber';


const formValidationSchema: yup.Schema<IProduct> = yup.object().shape({
    name: yup.string().required().min(3),
    value: yup.number().required(),
    typeOfSale: yup.string().required().min(3),
    externalId: yup.string().required().min(1),
    establishmentId: yup.string().required(),
    createdBy: yup.string().required(),
});

export const UpsertProduct: React.FC = () => {

    const establishmentId = localStorage.getItem('LOGGED_ESTABLISHMENT_ID');
    const userId = localStorage.getItem('LOGGED_USER_ID');

    const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();
    const { id = 'nova' } = useParams<'id'>();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const { userLogged } = useAuthContext();

    useEffect(() => {
        if (id !== 'nova') {
            setIsLoading(true);
            ProductService.getById(id)
                .then((result) => {
                    setIsLoading(false);

                    if (result instanceof Error) {
                        alert(result.message);
                        navigate('/product');
                    } else {
                        formRef.current?.setData(result);
                    }
                });
        } else {
            formRef.current?.setData({
                name: "",
                value: "",
                percentageOfPromotionalValue: "",
                promotionalValue: "",
                typeOfSale: "",
                externalId: "",
                establishmentId: "",
            });
        }
    }, [id]);


    const handleSave = (dados: IProduct) => {
        dados.createdBy = userLogged.id;
        dados.establishmentId = establishmentId ? establishmentId : "";
        dados.value = formatInputNumber(dados.value.toString());
        formValidationSchema.
            validate(dados, { abortEarly: false })
            .then((dadosValidados) => {
                setIsLoading(true);
                if (id === 'nova') {
                    ProductService
                        .create(dadosValidados)
                        .then((result) => {
                            setIsLoading(false);

                            if (result instanceof Error) {
                                alert(result.message);
                            } else {
                                if (isSaveAndClose()) {
                                    navigate('/product');
                                } else {
                                    navigate(`/product/detalhe/${result.id}`);
                                }
                            }
                        });
                } else {
                    ProductService
                        .update(id, dadosValidados)
                        .then((result) => {
                            setIsLoading(false);

                            if (result instanceof Error) {
                                alert(result.message);
                            } else {
                                if (isSaveAndClose()) {
                                    navigate('/product');
                                }
                            }
                        });
                }
            })
            .catch((errors: yup.ValidationError) => {
                const validationErrors: IVFormErrors = {};
                errors.inner.forEach(error => {
                    if (!error.path) return;
                    validationErrors[error.path] = error.message;
                });
                formRef.current?.setErrors(validationErrors);
            });
    };

    const handleDelete = async (id: string) => {
        if (window.confirm('Realmente deseja apagar?')) {
            await ProductService.deleteById(id, userId as string)
                .then(result => {
                    if (result instanceof Error) {
                        alert(result.message);
                    } else {
                        alert('Registro apagado com sucesso!');
                        navigate("/product")
                    }
                });
        }
    };

    return (
        <LayoutBaseDePagina
            barraDeFerramentas={
                <DetailTool
                    textoBotaoNovo='Nova'
                    mostrarBotaoSalvarEFechar
                    mostrarBotaoNovo={id !== 'nova'}
                    mostrarBotaoApagar={id !== 'nova'}

                    aoClicarEmSalvar={save}
                    aoClicarEmSalvarEFechar={saveAndClose}
                    aoClicarEmApagar={() => handleDelete(id)}
                    aoClicarEmVoltar={() => navigate('/product')}
                    aoClicarEmNovo={() => navigate('/product/detalhe/nova')}
                />
            }
        >
            {id === "nova" && (
                <VForm ref={formRef} onSubmit={handleSave}>
                    <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
                        <Grid container direction="column" padding={2} spacing={2}>
                            {isLoading && (
                                <Grid item>
                                    <LinearProgress variant='indeterminate' />
                                </Grid>
                            )}
                            <Grid item>
                                <Typography variant='h6'>Adicionar Produto</Typography>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='name'
                                        label='Nome'
                                        disabled={isLoading}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='value'
                                        label='Valor'
                                        disabled={isLoading}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='externalId'
                                        label='ID Externo'
                                        disabled={isLoading}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    < AutoCompleteTypeOfSale />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </VForm >
            )}
            {id !== "nova" && (
                <VForm ref={formRef} onSubmit={handleSave}>
                    <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
                        <Grid container direction="column" padding={2} spacing={2}>
                            {isLoading && (
                                <Grid item>
                                    <LinearProgress variant='indeterminate' />
                                </Grid>
                            )}
                            <Grid item>
                                <Typography variant='h6'>Atualizar Produto</Typography>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='name'
                                        label='Nome'
                                        disabled={isLoading}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='value'
                                        label='Valor'
                                        disabled={isLoading}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='externalId'
                                        label='ID Externo'
                                        disabled={isLoading}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    < AutoCompleteTypeOfSale />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </VForm >
            )}
        </LayoutBaseDePagina>
    );
};