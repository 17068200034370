import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, LinearProgress, Paper, Typography } from '@mui/material';

import { IPoints } from '../../shared/protocolos';
import { DetailTool } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { useAuthContext } from '../../shared/contexts/AuthContext'
import { PointsService } from '../../shared/services/points/pointsService';
import { VTextField, VForm, useVForm, IVFormErrors } from '../../shared/forms';
import { AutoCompleteUser } from './componets/AutoCompleteUser';


const formValidationSchema: yup.Schema<IPoints> = yup.object().shape({
    score: yup.number().required().default(0),
    status: yup.string().required().default("ATIVO"),
    userId: yup.string().required().min(3),
    establishmentId: yup.string().required(),
    createdBy: yup.string().required(),
});

export const UpsertPoints: React.FC = () => {

    const establishmentId = localStorage.getItem('LOGGED_ESTABLISHMENT_ID');
    const userId = localStorage.getItem('LOGGED_USER_ID');

    const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();
    const { id = 'nova' } = useParams<'id'>();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [userPointsId, setUserPointsId] = useState("");
    const { userLogged } = useAuthContext();

    useEffect(() => {
        if (id !== 'nova') {
            setIsLoading(true);
            PointsService.getById(id)
                .then((result) => {
                    setIsLoading(false);
                    if (result instanceof Error) {
                        alert(result.message);
                        navigate('/points');
                    } else {
                        setUserPointsId(result.userId);
                        formRef.current?.setData(result);
                    }
                });
        } else {
            formRef.current?.setData({
                score: ""
            });
        }
    }, [id]);


    const handleSave = (dados: IPoints) => {
        dados.createdBy = userLogged.id;
        dados.establishmentId = establishmentId ? establishmentId : "";
        dados.status = "ATIVO";
        if (id !== "nova") {
            dados.userId = userPointsId;
        }
        formValidationSchema.
            validate(dados, { abortEarly: false })
            .then((dadosValidados) => {
                setIsLoading(true);
                if (id === 'nova') {
                    PointsService
                        .create(dadosValidados)
                        .then((result) => {
                            setIsLoading(false);

                            if (result instanceof Error) {
                                alert(result.message);
                            } else {
                                if (isSaveAndClose()) {
                                    navigate('/points');
                                } else {
                                    navigate(`/points/detalhe/${result.id}`);
                                }
                            }
                        });
                } else {
                    PointsService
                        .update(id, { score: dadosValidados.score, status: dadosValidados.status, updatedBy: userId as any })
                        .then((result) => {
                            setIsLoading(false);

                            if (result instanceof Error) {
                                alert(result.message);
                            } else {
                                if (isSaveAndClose()) {
                                    navigate('/points');
                                }
                            }
                        });
                }
            })
            .catch((errors: yup.ValidationError) => {
                const validationErrors: IVFormErrors = {};
                errors.inner.forEach(error => {
                    if (!error.path) return;
                    validationErrors[error.path] = error.message;
                });
                formRef.current?.setErrors(validationErrors);
            });
    };

    const handleDelete = async (id: string) => {
        if (window.confirm('Realmente deseja apagar?')) {
            await PointsService.deleteById(id, userId as string)
                .then(result => {
                    if (result instanceof Error) {
                        alert(result.message);
                    } else {
                        alert('Registro apagado com sucesso!');
                        navigate("/points")
                    }
                });
        }
    };

    return (
        <LayoutBaseDePagina
            barraDeFerramentas={
                <DetailTool
                    textoBotaoNovo='Nova'
                    mostrarBotaoSalvarEFechar
                    mostrarBotaoNovo={id !== 'nova'}
                    mostrarBotaoApagar={id !== 'nova'}

                    aoClicarEmSalvar={save}
                    aoClicarEmSalvarEFechar={saveAndClose}
                    aoClicarEmApagar={() => handleDelete(id)}
                    aoClicarEmVoltar={() => navigate('/points')}
                    aoClicarEmNovo={() => navigate('/points/detalhe/nova')}
                />
            }
        >
            {id === "nova" && (
                <VForm ref={formRef} onSubmit={handleSave}>
                    <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
                        <Grid container direction="column" padding={2} spacing={2}>
                            {isLoading && (
                                <Grid item>
                                    <LinearProgress variant='indeterminate' />
                                </Grid>
                            )}
                            <Grid item>
                                <Typography variant='h6'>Adicionar Pontos</Typography>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='score'
                                        label='Pontos'
                                        disabled={isLoading}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    < AutoCompleteUser />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </VForm >
            )}
            {id !== "nova" && (
                <VForm ref={formRef} onSubmit={handleSave}>
                    <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
                        <Grid container direction="column" padding={2} spacing={2}>
                            {isLoading && (
                                <Grid item>
                                    <LinearProgress variant='indeterminate' />
                                </Grid>
                            )}
                            <Grid item>
                                <Typography variant='h6'>Atualizar Pontos</Typography>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='score'
                                        label='Pontos'
                                        disabled={isLoading}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </VForm >
            )}
        </LayoutBaseDePagina>
    );
};