import { Api } from '../api/axios-config';
import { Environment } from '../../environments';
import { IBenefitRedemption, IUpdateBenefitRedemption } from '../../protocolos';

type TBenefitRedemptionDataCount = {
    data: IBenefitRedemption[];
    totalCount: number;
}



type TAwardData = {
    data: {
        id: string,
        award: string,
        costPoints: number,
        amount: number,
    }[]
}

const listAllByEstablishmentId = async (establishmentId: string, sort: string, page: number, order: string, like?: string): Promise<TBenefitRedemptionDataCount | Error> => {
    try {
        const url = `/benefitRedemption/establisment/${establishmentId}?like=${like ? like : ""}&limit=${Environment.LIMIT}&skip=${Environment.SKIP}&page=${page}&sort=${sort}&order=${order}`;
        const { data } = await Api.get(url, {});
        if (data) {
            return {
                data: data.data,
                totalCount: data.count
            };
        }
        return new Error('Erro ao listar os registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
    }
};

const findAllAwardByEstablishmentId = async (establishmentId: string): Promise<TAwardData | Error> => {
    try {
        const url = `/benefitRedemption/award/establisment/${establishmentId}`;
        const { data } = await Api.get(url, {});
        if (data) {
            return {
                data: data.data
            };
        }
        return new Error('Erro ao listar os registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
    }
};

const getById = async (id: string): Promise<IBenefitRedemption | Error> => {
    try {
        const { data } = await Api.get(`/benefitRedemption/${id}`, {});

        if (data) {
            return data;
        }

        return new Error('Erro ao consultar o registro.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
    }
};

const create = async (dados: Omit<IBenefitRedemption, 'id'>): Promise<IBenefitRedemption | Error> => {
    try {
        const { data } = await Api.post<IBenefitRedemption>('/benefitRedemption', dados, {});

        if (data) {
            return data;
        }

        return new Error('Erro ao criar o registro.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
    }
};

const update = async (id: string, dados: IUpdateBenefitRedemption): Promise<void | Error> => {
    try {
        await Api.put(`/benefitRedemption/${id}`, dados, {});
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
    }
};

const deleteById = async (id: string, userId: string): Promise<void | Error> => {
    try {
        await Api.delete(`/benefitRedemption/${id}/${userId}`, {});
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
    }
};

export const BenefitRedemptionService = {
    findAllAwardByEstablishmentId,
    listAllByEstablishmentId,
    deleteById,
    getById,
    update,
    create
};