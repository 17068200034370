import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, LinearProgress, Paper, Typography } from '@mui/material';

import { PointsLogService } from 'shared/services';
import { IPointsLog } from '../../shared/protocolos';
import { DetailTool } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { useAuthContext } from '../../shared/contexts/AuthContext'
import { VTextField, VForm, useVForm, IVFormErrors } from '../../shared/forms';


const formValidationSchema: yup.Schema<IPointsLog> = yup.object().shape({
    description: yup.string().required(),
    point: yup.number().required().integer().positive().min(0),
    status: yup.string().required().default("COMPENSADO"),
    isRedemption: yup.boolean().required(),
    pointsId: yup.string().required().min(3),
    userId: yup.string().required().min(3),
    benefitRedemptionId: yup.string().default(""),
    establishmentId: yup.string().required(),
    createdBy: yup.string().required(),
});

export const AddPointsLog: React.FC = () => {

    const navigate = useNavigate();
    const { id = 'nova' } = useParams<'id'>();
    const { userId = 'nova' } = useParams<'userId'>();
    const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();

    const { userLogged } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const establishmentId = localStorage.getItem('LOGGED_ESTABLISHMENT_ID');

    useEffect(() => {
    }, []);


    const handleSave = (dados: IPointsLog) => {
        dados.createdBy = userLogged.id;
        dados.pointsId = id;
        dados.userId = userId;
        dados.isRedemption = false;
        dados.establishmentId = establishmentId ? establishmentId : "";
        formValidationSchema.
            validate(dados, { abortEarly: false })
            .then((dadosValidados) => {
                setIsLoading(true);
                PointsLogService
                    .create(dadosValidados)
                    .then((result) => {
                        setIsLoading(false);
                        if (result instanceof Error) {
                            alert(result.message);
                        } else {
                            if (isSaveAndClose()) {
                                navigate('/points');
                            } else {
                                navigate(`/points`);
                            }
                        }
                    });
            })
            .catch((errors: yup.ValidationError) => {
                const validationErrors: IVFormErrors = {};
                errors.inner.forEach(error => {
                    if (!error.path) return;
                    validationErrors[error.path] = error.message;
                });
                formRef.current?.setErrors(validationErrors);
            });
    };

    return (
        <LayoutBaseDePagina
            barraDeFerramentas={
                <DetailTool
                    mostrarBotaoSalvarEFechar={false}
                    mostrarBotaoNovo={false}
                    mostrarBotaoApagar={false}
                    aoClicarEmSalvar={save}
                    aoClicarEmSalvarEFechar={saveAndClose}
                    aoClicarEmVoltar={() => navigate('/points')}
                />
            }
        >
            <VForm ref={formRef} onSubmit={handleSave}>
                <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
                    <Grid container direction="column" padding={2} spacing={2}>
                        {isLoading && (
                            <Grid item>
                                <LinearProgress variant='indeterminate' />
                            </Grid>
                        )}
                        <Grid item>
                            <Typography variant='h6'>Adicionar Pontos</Typography>
                        </Grid>
                        <Grid container item direction="row" spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                <VTextField
                                    fullWidth
                                    name='description'
                                    label='Descrição'
                                    disabled={isLoading}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item direction="row" spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                <VTextField
                                    fullWidth
                                    name='point'
                                    label='Pontos'
                                    disabled={isLoading}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </VForm >
        </LayoutBaseDePagina>
    );
};