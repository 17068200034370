export function formatFoBrMoney(value: number): string {
    return `R$ ${value.toString().replace(".", ",")}`;
}

export function formatPoint(value: number): string {
    return value.toLocaleString('pt-BR');
}

export function formatInputNumber(value: string): number {
    const formattedValue = value.replace(/,/g, '.');
    return parseFloat(formattedValue);
}
