import QRCode from "react-qr-code";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { Toolbar } from "../../shared/components";
import { LayoutBaseDePagina } from "../../shared/layouts";


export const ShowQrCodeWinOnTime: React.FC = () => {

    const { id = 'nova' } = useParams<'id'>();
    const navigate = useNavigate();

    return (
        <LayoutBaseDePagina
            barraDeFerramentas={
                <Toolbar
                    mostrarBotaoVoltar={true}
                    aoClicarEmVoltar={() => { navigate(`/winOnTime`); }}
                    mostrarBotaoNovo={false}
                />}
        >
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="80vh">
                <QRCode
                    value={id}
                />
            </Box>

        </LayoutBaseDePagina>
    );
}