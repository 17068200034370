import { ICity } from '../../protocolos';
import axios from 'axios';

const getByUF = async (uf: string): Promise<ICity[] | Error> => {

    try {
        const { data } = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios?view=nivelado`);

        if (data) {
            return data;
        }
        return new Error('Erro ao consultar o registro.');

    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
    }
};

export const CityService = {
    getByUF
};