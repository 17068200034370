export const FormatDateISOFromBR = (dateIso: string) => {
    var date = new Date(dateIso);
    const dateFormated = date.toISOString().substring(0, 10).split("-");
    return dateFormated[2] + "/" + dateFormated[1] + "/" + dateFormated[0]
}

export const FormatDate = (dateIso: string) => {
    const dataSplit = (dateIso.replaceAll("/", '-')).split("-")
    return dataSplit[2] + "-" + dataSplit[1] + "-" + dataSplit[0] + " 00:00:00";
}

export const FormatDateBR = (timeStamp: string): string => {
    const date = new Date(timeStamp);
    return formatMonthOrDay(date.getDate()) + '/' + formatMonthOrDay(date.getMonth() + 1) + '/' + date.getFullYear() + " " + (date.getHours() + 3) + ":" + date.getMinutes() + ":" + date.getSeconds();
}

const formatMonthOrDay = (data: number) => {
    if (data < 10) {
        return "0" + data.toString()
    }
    return data;
}

export function formatDateToString(date: string): string {
    const [dateSplit, timeSplit] = date.split("T");
    const newDateSplit = dateSplit.split("-")
    const newTimeSplit = timeSplit.split(":")
    return `${newDateSplit[2]}/${newDateSplit[1]}/${newDateSplit[0]} ${newTimeSplit[0]}:${newTimeSplit[1]}:${newTimeSplit[2].substring(0, 2)}`;
}