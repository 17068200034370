import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import './shared/forms/TraducoesYup';
import { AppRoutes } from "./routes";
import { SideBar } from "./shared/components";
import { useAuthContext } from './shared/contexts/AuthContext';
import {
  Login,
  TermsOfUse,
  ContactForm,
  SelectEstablishment
} from './pages';
import {
  AuthProvider,
  DrawerProvider,
  AppThemeProvider,
  StateAndCitiesProvider,
  SelectedEstablishmentForListProductProvider
} from "./shared/contexts";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated } = useAuthContext();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return children;
};

export const App = () => {
  return (
    <AuthProvider>
      <AppThemeProvider>
        <SelectedEstablishmentForListProductProvider>
          <StateAndCitiesProvider>
            <DrawerProvider>
              <BrowserRouter>
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/contact" element={<ContactForm />} />
                  <Route path="/termsOfUse" element={<TermsOfUse />} />
                  <Route path="/selectEstablishment/:id" element={<SelectEstablishment />} />
                  <Route
                    path="/*"
                    element={
                      <ProtectedRoute>
                        <SideBar>
                          <AppRoutes />
                        </SideBar>
                      </ProtectedRoute>
                    }
                  />
                </Routes>
              </BrowserRouter>
            </DrawerProvider>
          </StateAndCitiesProvider>
        </SelectedEstablishmentForListProductProvider>
      </AppThemeProvider>
    </AuthProvider>
  );
}
