import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, LinearProgress, Paper, Typography } from '@mui/material';

import { DetailTool } from '../../shared/components';
import { IPointsLog } from '../../shared/protocolos';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { useAuthContext } from '../../shared/contexts/AuthContext';
import { AutoCompleteUser } from 'pages/points/componets/AutoCompleteUser';
import { VTextField, VForm, useVForm, IVFormErrors } from '../../shared/forms';
import { BenefitRedemptionService, PointsLogService } from '../../shared/services';
import { AutoCompleteAward } from './componets/AutoCompleteAward';
import { AutoCompleteAllUserPoints } from './componets/AutoCompleteAllUserPoints';

const formValidationSchema: yup.Schema<IPointsLog> = yup.object().shape({
    description: yup.string().required(),
    point: yup.number().required().integer(),
    point_user: yup.number().integer(),
    status: yup.string().required().default("COMPENSADO"),
    isRedemption: yup.boolean().required(),
    pointsId: yup.string().required(),
    benefitRedemptionId: yup.string(),
    userId: yup.string().required(),
    establishmentId: yup.string().required(),
    createdBy: yup.string().required(),
});

export const UpsertRedemption: React.FC = () => {

    const userId = localStorage.getItem('LOGGED_USER_ID');
    const establishmentId = localStorage.getItem('LOGGED_ESTABLISHMENT_ID');
    const [pointId, setPointId] = useState("");
    const [amount, setAmount] = useState(0);

    const navigate = useNavigate();
    const { userLogged } = useAuthContext();
    const { id = 'nova' } = useParams<'id'>();
    const [isLoading, setIsLoading] = useState(false);
    const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();

    useEffect(() => {
        if (id !== 'nova') {
            setIsLoading(true);
            PointsLogService.getById(id)
                .then((result) => {
                    setIsLoading(false);
                    if (result instanceof Error) {
                        alert(result.message);
                        navigate('/redemption');
                    } else {
                        formRef.current?.setData(result);
                    }
                });
        } else {
            formRef.current?.setData({
                description: "",
                point: "",
                status: "",
                isRedemption: "",
                pointsId: "",
                userId: "",
                establishmentId: "",
            });
        }
    }, [id]);


    const handleSave = async (dados: IPointsLog) => {
        if ((!!dados.point_user && dados.point_user >= dados.point) || amount > 0) {
            dados.createdBy = userLogged.id;
            dados.establishmentId = establishmentId ? establishmentId : "";
            dados.isRedemption = true;
            dados.pointsId = pointId;
            formValidationSchema.
                validate(dados, { abortEarly: false })
                .then(async (dadosValidados) => {
                    setIsLoading(true);
                    if (id === 'nova') {
                        try {
                            PointsLogService
                                .create(dadosValidados)
                                .then((result) => {
                                    setIsLoading(false);
                                    if (result instanceof Error) {
                                        alert(result.message);
                                    } else {
                                        if (isSaveAndClose()) {
                                            navigate('/redemption');
                                        } else {
                                            navigate(`/redemption/detalhe/${result.id}`);
                                        }
                                    }
                                });
                        } catch (error) {
                            console.error('Erro ao enviar imagem para o S3:', error);
                        }
                    } else {
                        PointsLogService
                            .update(id, dados)
                            .then((result) => {
                                setIsLoading(false);
                                if (result instanceof Error) {
                                    alert(result.message);
                                } else {
                                    if (isSaveAndClose()) {
                                        navigate('/redemption');
                                    }
                                }
                            });
                    }
                })
                .catch((errors: yup.ValidationError) => {
                    const validationErrors: IVFormErrors = {};
                    errors.inner.forEach(error => {
                        if (!error.path) return;
                        validationErrors[error.path] = error.message;
                    });
                    formRef.current?.setErrors(validationErrors);
                });
        } else {
            alert("Usuário sem pontos necessários");
        }
    };

    const handleDelete = async (id: string) => {
        if (window.confirm('Realmente deseja apagar?')) {
            await PointsLogService.deleteById(id, userId as string)
                .then(result => {
                    if (result instanceof Error) {
                        alert(result.message);
                    } else {
                        alert('Registro apagado com sucesso!');
                        navigate("/redemption")
                    }
                });
        }
    };

    return (
        <LayoutBaseDePagina
            barraDeFerramentas={
                <DetailTool
                    textoBotaoNovo='Nova'
                    mostrarBotaoSalvarEFechar
                    mostrarBotaoSalvar={false}
                    mostrarBotaoNovo={id !== 'nova'}
                    mostrarBotaoApagar={id !== 'nova'}
                    aoClicarEmSalvar={save}
                    aoClicarEmSalvarEFechar={saveAndClose}
                    aoClicarEmApagar={() => handleDelete(id)}
                    aoClicarEmVoltar={() => navigate('/redemption')}
                    aoClicarEmNovo={() => navigate('/redemption/detalhe/nova')}
                />
            }
        >
            {id === "nova" && (
                <VForm ref={formRef} onSubmit={handleSave}>
                    <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
                        <Grid container direction="column" padding={2} spacing={2}>
                            {isLoading && (
                                <Grid item>
                                    <LinearProgress variant='indeterminate' />
                                </Grid>
                            )}
                            <Grid item>
                                <Typography variant='h6'>Adicionar Resgate</Typography>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='description'
                                        label='Descrição'
                                        disabled={isLoading}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <AutoCompleteAward
                                        establismentId={establishmentId as any}
                                        onAwardSelected={(award) => {
                                            if (award === null) {
                                                formRef.current?.setFieldValue('point', "");
                                                formRef.current?.setFieldValue('amount', "");
                                                setAmount(0)
                                            } else {
                                                formRef.current?.setFieldValue('point', String(award.costPoints));
                                                formRef.current?.setFieldValue('amount', String(award.amount));
                                                setAmount(award.amount)
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='amount'
                                        label='Quantidade'
                                        disabled={true}
                                        initialValue={''}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='point'
                                        label='Pontos para Resgate'
                                        disabled={true}
                                        initialValue={''}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <AutoCompleteAllUserPoints
                                        establismentId={establishmentId as any}
                                        onUserSelected={(user) => {
                                            if (user === null) {
                                                formRef.current?.setFieldValue('point_user', "");
                                                setPointId("");
                                            } else {
                                                formRef.current?.setFieldValue('point_user', String(user.score));
                                                setPointId(user.pointsId);
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='point_user'
                                        label='Pontos do Usuário'
                                        disabled={true}
                                        initialValue={''}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </VForm >
            )}
            {id !== "nova" && (
                <VForm ref={formRef} onSubmit={handleSave}>
                    <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
                        <Grid container direction="column" padding={2} spacing={2}>
                            {isLoading && (
                                <Grid item>
                                    <LinearProgress variant='indeterminate' />
                                </Grid>
                            )}
                            <Grid item>
                                <Typography variant='h6'>Atualizar Pontos</Typography>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='description'
                                        label='Descrição'
                                        disabled={isLoading}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <AutoCompleteAward
                                        establismentId={establishmentId as any}
                                        onAwardSelected={(award) => {
                                            if (award === null) {
                                                formRef.current?.setFieldValue('point', "");
                                                formRef.current?.setFieldValue('amount', "");
                                                setAmount(0)
                                            } else {
                                                formRef.current?.setFieldValue('point', String(award.costPoints));
                                                formRef.current?.setFieldValue('amount', String(award.amount));
                                                setAmount(award.amount)
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='amount'
                                        label='Quantidade'
                                        disabled={true}
                                        initialValue={''}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='point'
                                        label='Pontos para Resgate'
                                        disabled={true}
                                        initialValue={''}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <AutoCompleteAllUserPoints
                                        establismentId={establishmentId as any}
                                        onUserSelected={(user) => {
                                            if (user === null) {
                                                formRef.current?.setFieldValue('point_user', "");
                                                setPointId("");
                                            } else {
                                                formRef.current?.setFieldValue('point_user', String(user.score));
                                                setPointId(user.pointsId);
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='point_user'
                                        label='Pontos do Usuário'
                                        disabled={true}
                                        initialValue={''}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </VForm >
            )}
        </LayoutBaseDePagina>
    );
};