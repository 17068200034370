import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';

import { useField } from '@unform/core';

type TAutoCompleteOption = {
    type: string;
}

interface IAutoCompleteTypeProps {
    isExternalLoading?: boolean;
}

export const AutoCompleteType: React.FC<IAutoCompleteTypeProps> = ({ isExternalLoading = false }) => {

    const { fieldName, registerField, defaultValue, error, clearError } = useField('type');
    const [selectedType, setSelectedType] = useState<string | undefined>(defaultValue);
    const [isLoading, setIsLoading] = useState(true);
    const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);
    const [busca, setBusca] = useState('');

    useEffect(() => {
        registerField({
            name: fieldName,
            getValue: () => selectedType,
            setValue: (_, newSelectedType) => setSelectedType(newSelectedType),
        });
    }, [registerField, fieldName, selectedType]);

    useEffect(() => {
        setIsLoading(false)
        setOpcoes([
            { type: "BENEFICENTE" },
            { type: "CAMPANHA" }
        ])
    }, [busca]);

    const autoCompleteSelectedOption = useMemo(() => {
        if (!selectedType) return null;

        const selectedOption = opcoes.find(opcao => opcao.type === selectedType);
        if (!selectedOption) return null;

        return selectedOption;
    }, [selectedType, opcoes]);


    return (
        <Autocomplete
            openText='Abrir'
            closeText='Fechar'
            noOptionsText='Sem opções'
            loadingText='Carregando...'
            clearText='Limpar'

            disablePortal

            options={opcoes}
            getOptionLabel={(option) => option.type ?? option}
            loading={isLoading}
            disabled={isExternalLoading}
            value={autoCompleteSelectedOption}
            onInputChange={(_, newValue) => setBusca(newValue)}
            onChange={(_, newValue) => { setSelectedType(newValue?.type); setBusca(''); clearError(); }}
            popupIcon={(isExternalLoading || isLoading) ? <CircularProgress size={28} /> : undefined}
            renderInput={(params) => (
                <TextField
                    {...params}

                    label="Tipo"
                    error={!!error}
                    helperText={error}
                />
            )}
        />
    );
};