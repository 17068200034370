import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableFooter,
    LinearProgress,
    Pagination,
    IconButton,
    Icon,
    Typography
}
    from '@mui/material'

import { EstablishmentService } from '../../shared/services/establishment/establishmentService';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { IEstablishment } from '../../shared/protocolos';
import { Toolbar } from '../../shared/components';
import { useDebounce } from '../../shared/hooks';
import { Environment } from '../../shared/environments';

export const Establishment: React.FC = () => {

    const userId = localStorage.getItem('LOGGED_USER_ID');

    const [searchParams, setSearchParams] = useSearchParams();
    const { debounce } = useDebounce();
    const navigate = useNavigate();

    const [rows, setRows] = useState<IEstablishment[]>([])
    const [totalCount, setTotalCount] = useState(0)
    const [isLoading, setIsLoading] = useState(true)

    const busca = useMemo(() => {
        return searchParams.get('busca') || '';
    }, [searchParams]);

    const pagina = useMemo(() => {
        return Number(searchParams.get('pagina') || "1");
    }, [searchParams]);

    useEffect(() => {
        setIsLoading(true);
        debounce(async () => {
            handleTableList();
        });
    }, [busca, pagina]);

    const handleTableList = async () => {
        await EstablishmentService.listAll("id", pagina, "asc", busca)
            .then((result) => {
                setIsLoading(false);

                if (result instanceof Error) {
                    alert(result.message);
                } else {
                    setTotalCount(result.totalCount);
                    setRows(result.data);
                }
            });
    }

    const handleDelete = async (id: string) => {
        if (window.confirm('Realmente deseja apagar?')) {
            await EstablishmentService.deleteById(id, userId as string)
                .then(result => {
                    if (result instanceof Error) {
                        alert(result.message);
                    } else {
                        handleTableList();
                        alert('Registro apagado com sucesso!');
                    }
                });
        }
    };

    return (
        <LayoutBaseDePagina
            barraDeFerramentas={
                <Toolbar
                    mostrarInputBusca
                    textoDaBusca={busca}
                    textoBotaoNovo='Nova'
                    aoClicarEmNovo={() => { navigate('/establishment/detalhe/nova') }}
                    aoMudarTextoDeBusca={texto => setSearchParams({ busca: texto, pagina: '1' }, { replace: true })}
                />
            }
        >
            <TableContainer component={Paper} variant='outlined' sx={{ m: 1, width: 'auto' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width={100}>Ações</TableCell>
                            <TableCell>Nome</TableCell>
                            <TableCell>CNPJ</TableCell>
                            <TableCell>Telefone</TableCell>
                            <TableCell>Nome Fantasia</TableCell>
                            <TableCell>Razao Social</TableCell>
                            <TableCell>Endereço</TableCell>
                            <TableCell>Bairro</TableCell>
                            <TableCell>CEP</TableCell>
                            <TableCell>Cidade</TableCell>
                            <TableCell>UF</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => (
                            // whiteSpace="nowrap" textOverflow="ellipsis"
                            <TableRow key={row.id}>
                                <TableCell>
                                    <IconButton onClick={() => handleDelete(row.id as any)}>
                                        <Icon>
                                            delete
                                        </Icon>
                                    </IconButton>
                                    <IconButton onClick={() => navigate(`/establishment/detalhe/${row.id}`)}>
                                        <Icon>
                                            edit
                                        </Icon>
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" variant='inherit'>
                                        {row.name}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {row.cnpj}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {row.telefone}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {row.nomeFantasia}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {row.razaoSocial}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {row.endereco}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {row.bairro}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {row.cep}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {row.cidade}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {row.uf}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    {totalCount === 0 && !isLoading &&
                        (
                            <caption>{Environment.LISTAGEM_VAZIA}</caption>
                        )
                    }
                    <TableFooter>
                        {isLoading && (
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <LinearProgress variant='indeterminate' />
                                </TableCell>
                            </TableRow>
                        )}
                        {(totalCount > 0 && totalCount > Environment.LIMIT) && (
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <Pagination
                                        page={pagina}
                                        count={Math.ceil(totalCount / Environment.LIMIT)}
                                        onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
                                    />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableFooter>
                </Table>
            </TableContainer>
        </LayoutBaseDePagina>
    );
};