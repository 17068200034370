import { IWinOnTime } from '../../protocolos';
import { Environment } from '../../environments';
import { Api } from '../api/axios-config';

type TWinOnTimeDataCount = {
    data: IWinOnTime[];
    totalCount: number;
}

const listAll = async (sort: string, page: number, order: string, establishmentId: string, like?: string): Promise<TWinOnTimeDataCount | Error> => {
    try {
        const url = `/winOnTime/establishment/${establishmentId}?limit=${Environment.LIMIT}&skip=${Environment.SKIP}&sort=${sort}&like=${like ? like : ""}&page=${page}&order=${order}`;
        const { data } = await Api.get(url, {});
        if (data) {
            return {
                data: data.data,
                totalCount: data.count
            };
        }
        return new Error('Erro ao listar os registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
    }
};

const getById = async (id: string): Promise<IWinOnTime | Error> => {
    try {
        const { data } = await Api.get(`/winOnTime/${id}`, {});

        if (data) {
            return data;
        }

        return new Error('Erro ao consultar o registro.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
    }
};

const create = async (dados: Omit<IWinOnTime, 'id'>): Promise<IWinOnTime | Error> => {
    try {
        const { data } = await Api.post<IWinOnTime>('/winOnTime', dados, {});

        if (data) {
            return data;
        }

        return new Error('Erro ao criar o registro.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
    }
};

const update = async (id: string, dados: IWinOnTime): Promise<void | Error> => {
    try {
        await Api.put(`/winOnTime/${id}`, dados, {});
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
    }
};

const deleteById = async (id: string, userId: string): Promise<void | Error> => {
    try {
        await Api.delete(`/winOnTime/${id}?userId=${userId}`, {});
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
    }
};

export const WinOnTimeService = {
    listAll,
    deleteById,
    getById,
    update,
    create
};