import { ReactNode } from 'react';
import { Icon, IconButton, Theme, Avatar, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';

import logo from '../../assests/logo.png'

import { useDrawerContext } from '../contexts';

interface ILayoutBaseDePaginaProps {
    barraDeFerramentas?: ReactNode;
    children: React.ReactNode;
}
export const LayoutBaseDePagina: React.FC<ILayoutBaseDePaginaProps> = ({ children, barraDeFerramentas }) => {
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const theme = useTheme();

    const { toggleDrawerOpen } = useDrawerContext();

    return (
        <Box height="100%" display="flex" flexDirection="column" gap={1}>
            <Box padding={1} display="flex" alignItems="center" justifyContent="center" gap={1} height={theme.spacing(smDown ? 6 : mdDown ? 8 : 12)}>
                {smDown && (
                    <IconButton onClick={toggleDrawerOpen}>
                        <Icon>menu</Icon>
                    </IconButton>
                )}
                <Avatar src={logo} sx={{ height: theme.spacing(12), width: theme.spacing(12), borderRadius: theme.spacing(0) }} />
            </Box>
            {barraDeFerramentas &&
                (<Box>
                    {barraDeFerramentas}
                </Box>)}
            <Box flex={1} overflow="auto">
                {children}
            </Box>
        </Box>
    );
};