import { IWinOnTimeOnProducts } from '../../protocolos';
import { Environment } from '../../environments';
import { Api } from '../api/axios-config';
import { IUpsertWinOnTimeOnProductsProtocol } from '../../protocolos/win-on-time/upsertWinOnTimeOnProductsProtocol';

type IWinOnTimeOnProductsDataCount = {
    data: IWinOnTimeOnProducts[];
    totalCount: number;
}

const listAllWinOnTimeOnProductsByWinOnTimeId = async (sort: string, page: number, order: string, winOnTimeId: string, like?: string): Promise<IWinOnTimeOnProductsDataCount | Error> => {
    try {
        const url = `/winOnTimeOnProducts/${winOnTimeId}?limit=${Environment.LIMIT}&skip=${Environment.SKIP}&sort=${sort}&like=${like ? like : ""}&page=${page}&order=${order}`;
        const { data } = await Api.get(url, {});
        if (data) {
            return {
                data: data.data,
                totalCount: data.count
            };
        }
        return new Error('Erro ao listar os registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
    }
};

const upsertWinOnTimeOnProducts = async (dados: IUpsertWinOnTimeOnProductsProtocol): Promise<void | Error> => {
    try {
        await Api.post<IWinOnTimeOnProducts>('/winOnTimeOnProducts', dados, {});

        return;
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
    }
};

export const WinOnTimeOnProductsService = {
    listAllWinOnTimeOnProductsByWinOnTimeId,
    upsertWinOnTimeOnProducts
};