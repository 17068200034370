import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { useDrawerContext } from '../shared/contexts';
import {
    User,
    Awards,
    Points,
    Product,
    Dashboard,
    WinOnTime,
    UpsertUser,
    Redemption,
    UpsertPoints,
    AddPointsLog,
    UpsertAwards,
    Establishment,
    UpsertProduct,
    WinOnTimeReport,
    UpsertWinOnTime,
    UpsertRedemption,
    HistoryPointsLog,
    UpsertEstablishment,
    ShowQrCodeWinOnTime,
    AddProductsWithWinOnTime,
} from '../pages';

export const AppRoutes = () => {
    const { setDrawerOptions } = useDrawerContext();

    useEffect(() => {
        setDrawerOptions([
            {
                icon: 'home',
                path: '/home',
                label: 'Home',
            },
            {
                icon: 'business_center',
                path: '/product',
                label: 'Produtos',
            },
            {
                icon: 'discount',
                path: '/winOnTime',
                label: 'Ganhe na Hora',
            },
            {
                icon: 'data_saver_on',
                path: '/points',
                label: 'Pontos',
            },
            {
                icon: 'redeem',
                path: '/redemption',
                label: 'Resgates',
            },
            {
                icon: 'emoji_events',
                path: '/awards',
                label: 'Prêmios',
            },
            {
                icon: 'content_paste',
                path: '/winOnTimeReport',
                label: 'Relatórios',
            },
        ]);
    }, [setDrawerOptions]);

    return (
        <Routes>
            <Route path="/home" element={<Dashboard />} />
            <Route path="/winOnTime" element={<WinOnTime />} />
            <Route path="/winOnTime/qrcode/:id" element={<ShowQrCodeWinOnTime />} />
            <Route path="/winOnTime/detalhe/:id" element={<UpsertWinOnTime />} />
            <Route path="/winOnTime/add/products/:id" element={<AddProductsWithWinOnTime />} />
            <Route path="/winOnTimeReport" element={<WinOnTimeReport />} />
            <Route path="/product" element={<Product />} />
            <Route path="/product/establishment/:id" element={<Product />} />
            <Route path="/product/detalhe/:id" element={<UpsertProduct />} />
            <Route path="/user" element={<User />} />
            <Route path="/user/detalhe/:id" element={<UpsertUser />} />
            <Route path="/points" element={<Points />} />
            <Route path="/points/detalhe/:id" element={<UpsertPoints />} />
            <Route path="/points/add/:id/:userId" element={<AddPointsLog />} />
            <Route path="/points/history/:pointsId/:userId" element={<HistoryPointsLog />} />
            <Route path="/awards" element={<Awards />} />
            <Route path="/awards/detalhe/:id" element={<UpsertAwards />} />
            <Route path="/redemption" element={<Redemption />} />
            <Route path="/redemption/detalhe/:id" element={<UpsertRedemption />} />
            <Route path="/establishment" element={<Establishment />} />
            <Route path="/establishment/detalhe/:id" element={<UpsertEstablishment />} />
            <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
    );
};
