import { IWinOnTimeConsume } from '../../protocolos';
import { Environment } from '../../environments';
import { Api } from '../api/axios-config';

type IWinOnTimeConsumeDataCount = {
    data: IWinOnTimeConsume[];
    totalCount: number;
}

const findAllWinOnTimeConsumeByWinOnTimeIdAndDateRange = async (sort: string, page: number, order: string, winOnTimeId: string, dateStart: string, dateEnd: string, like?: string): Promise<IWinOnTimeConsumeDataCount | Error> => {
    try {
        const url = `/winOnTimeConsume/dateRange/${winOnTimeId}?limit=${Environment.LIMIT}&skip=${Environment.SKIP}&sort=${sort}&like=${like ? like : ""}&page=${page}&order=${order}&dateStart=${dateStart}&dateEnd=${dateEnd}`;
        const { data } = await Api.get(url, {});
        if (data) {
            return {
                data: data.data,
                totalCount: data.count
            };
        }
        return new Error('Erro ao listar os registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
    }
};

export const WinOnTimeConsumeService = {
    findAllWinOnTimeConsumeByWinOnTimeIdAndDateRange
};