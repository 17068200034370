import { IProduct, IUpdateProduct } from '../../protocolos';
import { Environment } from '../../environments';
import { Api } from '../api/axios-config';

type TProductDataCount = {
    data: IProduct[];
    totalCount: number;
}

const listAll = async (sort: string, page: number, order: string, like?: string): Promise<TProductDataCount | Error> => {
    try {
        const url = `/product?limit=${Environment.LIMIT}&skip=${Environment.SKIP}&sort=${sort}&like=${like ? like : ""}&page=${page}&order=${order}`;
        const { data } = await Api.get(url, {});
        if (data) {
            return {
                data: data.data,
                totalCount: data.count
            };
        }
        return new Error('Erro ao listar os registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
    }
};

const listAllByEstablishment = async (sort: string, page: number, order: string, establishmentId: string, like?: string): Promise<TProductDataCount | Error> => {
    try {
        const url = `/product/establisment/${establishmentId}?limit=${Environment.LIMIT}&skip=${Environment.SKIP}&sort=${sort}&like=${like ? like : ""}&page=${page}&order=${order}`;
        const { data } = await Api.get(url, {});
        if (data) {
            return {
                data: data.data,
                totalCount: data.count
            };
        }
        return new Error('Erro ao listar os registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
    }
};

const getById = async (id: string): Promise<IProduct | Error> => {
    try {
        const { data } = await Api.get(`/product/${id}`, {});

        if (data) {
            return data;
        }

        return new Error('Erro ao consultar o registro.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
    }
};

const create = async (dados: Omit<IProduct, 'id'>): Promise<IProduct | Error> => {
    try {
        const { data } = await Api.post<IProduct>('/product', dados, {});

        if (data) {
            return data;
        }

        return new Error('Erro ao criar o registro.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
    }
};

const update = async (id: string, dados: IUpdateProduct): Promise<void | Error> => {
    try {
        await Api.put(`/product/${id}`, dados, {});
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
    }
};

const deleteById = async (id: string, userId: string): Promise<void | Error> => {
    try {
        await Api.delete(`/product/${id}?userId=${userId}`, {});
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
    }
};

export const ProductService = {
    listAll,
    listAllByEstablishment,
    deleteById,
    getById,
    update,
    create
};