import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';

import { useField } from '@unform/core';

type TAutoCompleteOption = {
    typeOfSale: string;
}

interface IAutoCompleteTypeOfSaleProps {
    isExternalLoading?: boolean;
}

export const AutoCompleteTypeOfSale: React.FC<IAutoCompleteTypeOfSaleProps> = ({ isExternalLoading = false }) => {

    const { fieldName, registerField, defaultValue, error, clearError } = useField('typeOfSale');
    const [selectedStatus, setSelectedStatus] = useState<string | undefined>(defaultValue);
    const [isLoading, setIsLoading] = useState(true);
    const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);
    const [busca, setBusca] = useState('');

    useEffect(() => {
        registerField({
            name: fieldName,
            getValue: () => selectedStatus,
            setValue: (_, newSelectedStatus) => setSelectedStatus(newSelectedStatus),
        });
    }, [registerField, fieldName, selectedStatus]);

    useEffect(() => {
        setIsLoading(false)
        setOpcoes([
            { typeOfSale: "PRODUTO" },
            { typeOfSale: "COMBUSTÍVEL" }
        ])
    }, [busca]);

    const autoCompleteSelectedOption = useMemo(() => {
        if (!selectedStatus) return null;

        const selectedOption = opcoes.find(opcao => opcao.typeOfSale === selectedStatus);
        if (!selectedOption) return null;

        return selectedOption;
    }, [selectedStatus, opcoes]);


    return (
        <Autocomplete
            openText='Abrir'
            closeText='Fechar'
            noOptionsText='Sem opções'
            loadingText='Carregando...'
            clearText='Limpar'

            disablePortal

            options={opcoes}
            getOptionLabel={(option) => option.typeOfSale ?? option}
            loading={isLoading}
            disabled={isExternalLoading}
            value={autoCompleteSelectedOption}
            onInputChange={(_, newValue) => setBusca(newValue)}
            onChange={(_, newValue) => { setSelectedStatus(newValue?.typeOfSale); setBusca(''); clearError(); }}
            popupIcon={(isExternalLoading || isLoading) ? <CircularProgress size={28} /> : undefined}
            renderInput={(params) => (
                <TextField
                    {...params}

                    label="Tipo de Venda"
                    error={!!error}
                    helperText={error}
                />
            )}
        />
    );
};