import { createContext, useState, useCallback, useContext } from "react";
import { CityService } from "../services";
import { ICity } from "../protocolos";

interface IStateAndCitiesContextData {
    selectedUFContext: string;
    setSelectedUFContext: (uf: string | undefined) => void;
    selectedCitiesContext: string[];
    setSelectedCitiesContext: () => void;
}
interface IStateAndCitiesProviderProps {
    children: React.ReactNode
}
const StateAndCitiesContext = createContext({} as IStateAndCitiesContextData);

export const useStateAndCitiesContext = () => {
    return useContext(StateAndCitiesContext);
}

export const StateAndCitiesProvider: React.FC<IStateAndCitiesProviderProps> = ({ children }) => {

    const [selectedCitiesContext, setselectedCities] = useState<string[]>([]);
    const [selectedUFContext, setSelectedUF] = useState("");

    const handleSelectedUF = useCallback((uf: string | undefined) => {
        if (!uf) {
            return
        }
        setSelectedUF(uf);
    }, [])

    const handleSelectedCities = async () => {
        if (selectedUFContext === "") {
            return;
        }
        const result = await CityService.getByUF(selectedUFContext) as ICity[];
        const cities: string[] = [];
        result.map((city) => {
            cities.push(city["municipio-nome"])
        })
        setselectedCities(cities)
    }

    return (
        <StateAndCitiesContext.Provider value={{ selectedUFContext, setSelectedUFContext: handleSelectedUF, selectedCitiesContext, setSelectedCitiesContext: handleSelectedCities }}>
            {children}
        </StateAndCitiesContext.Provider>
    );
}