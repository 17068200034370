import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, LinearProgress, Paper, Typography } from '@mui/material';

import { DetailTool } from '../../shared/components';
import { IWinOnTime } from '../../shared/protocolos';
import { WinOnTimeService } from '../../shared/services';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { useAuthContext } from '../../shared/contexts/AuthContext'
import { VTextField, VForm, useVForm, IVFormErrors } from '../../shared/forms';
import { AutoCompleteStatus } from '../user/components/AutoCompleteStatus';
import { AutoCompleteShowInApp } from './components/AutoCompleteShowInApp';
import { AutoCompleteType } from './components/AutoCompleteType';

const formValidationSchema: yup.Schema<IWinOnTime> = yup.object().shape({
    establishmentId: yup.string().required().min(36).max(40),
    description: yup.string().required().min(3).max(80),
    userPromoter: yup.string().default("Não aplicado"),
    showInApp: yup.boolean().required().default(false),
    type: yup.string().required().min(3).max(20),
    status: yup.string().required().min(3).max(80),
    createdBy: yup.string().required()
});

export const UpsertWinOnTime: React.FC = () => {

    const establishmentId = localStorage.getItem('LOGGED_ESTABLISHMENT_ID');
    const userId = localStorage.getItem('LOGGED_USER_ID');

    const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();
    const { id = 'nova' } = useParams<'id'>();
    const navigate = useNavigate();
    const { userLogged } = useAuthContext();

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (id !== 'nova') {
            setIsLoading(true);
            WinOnTimeService.getById(id)
                .then((result) => {
                    setIsLoading(false);

                    if (result instanceof Error) {
                        alert(result.message);
                        navigate('/winOnTime');
                    } else {
                        formRef.current?.setData(result);
                    }
                });
        } else {
            formRef.current?.setData({
                establishmentId: "",
                description: "",
                userPromoter: "",
                showInApp: "",
                type: "",
                discount: "",
                discountType: "",
                status: "",
                products: "",
                expirationAt: "",

            });
        }
    }, [id]);


    const handleSave = (dados: IWinOnTime) => {
        dados.createdBy = userLogged.id;
        dados.establishmentId = establishmentId ? establishmentId : "";
        formValidationSchema.
            validate(dados, { abortEarly: false })
            .then((dadosValidados) => {
                setIsLoading(true);
                if (id === 'nova') {
                    WinOnTimeService
                        .create(dadosValidados)
                        .then((result) => {
                            setIsLoading(false);
                            if (result instanceof Error) {
                                alert(result.message);
                            } else {
                                if (isSaveAndClose()) {
                                    navigate('/winOnTime');
                                } else {
                                    navigate(`/winOnTime/detalhe/${result.id}`);
                                }
                            }
                        });
                } else {
                    WinOnTimeService
                        .update(id, dadosValidados)
                        .then((result) => {
                            setIsLoading(false);
                            if (result instanceof Error) {
                                alert(result.message);
                            } else {
                                if (isSaveAndClose()) {
                                    navigate('/winOnTime');
                                }
                            }
                        });
                }
            })
            .catch((errors: yup.ValidationError) => {
                const validationErrors: IVFormErrors = {};
                errors.inner.forEach(error => {
                    if (!error.path) return;
                    validationErrors[error.path] = error.message;
                });
                formRef.current?.setErrors(validationErrors);
            });
    };

    const handleDelete = async (id: string) => {
        if (window.confirm('Realmente deseja apagar?')) {
            await WinOnTimeService.deleteById(id, userId as string)
                .then(result => {
                    if (result instanceof Error) {
                        alert(result.message);
                    } else {
                        alert('Registro apagado com sucesso!');
                        navigate("/winOnTime")
                    }
                });
        }
    };

    return (
        <LayoutBaseDePagina
            barraDeFerramentas={
                <DetailTool
                    textoBotaoNovo='Nova'
                    mostrarBotaoSalvarEFechar
                    mostrarBotaoNovo={id !== 'nova'}
                    mostrarBotaoApagar={id !== 'nova'}

                    aoClicarEmSalvar={save}
                    aoClicarEmSalvarEFechar={saveAndClose}
                    aoClicarEmApagar={() => handleDelete(id)}
                    aoClicarEmVoltar={() => navigate('/winOnTime')}
                    aoClicarEmNovo={() => navigate('/winOnTime/detalhe/nova')}
                />
            }
        >
            {id === "nova" && (
                <VForm ref={formRef} onSubmit={handleSave}>
                    <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
                        <Grid container direction="column" padding={2} spacing={2}>
                            {isLoading && (
                                <Grid item>
                                    <LinearProgress variant='indeterminate' />
                                </Grid>
                            )}
                            <Grid item>
                                <Typography variant='h6'>Adicionar Ganhe na Hora</Typography>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='description'
                                        label='Descrição'
                                        disabled={isLoading}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <AutoCompleteShowInApp />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <AutoCompleteType />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <AutoCompleteStatus />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='userPromoter'
                                        label='Promotor'
                                        disabled={isLoading}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </VForm >
            )}
            {id !== "nova" && (
                <VForm ref={formRef} onSubmit={handleSave}>
                    <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
                        <Grid container direction="column" padding={2} spacing={2}>
                            {isLoading && (
                                <Grid item>
                                    <LinearProgress variant='indeterminate' />
                                </Grid>
                            )}
                            <Grid item>
                                <Typography variant='h6'>Atualizar Ganhe na Hora</Typography>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='description'
                                        label='Descrição'
                                        disabled={isLoading}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <AutoCompleteShowInApp />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <AutoCompleteType />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <AutoCompleteStatus />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='userPromoter'
                                        label='Promotor'
                                        disabled={isLoading}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </VForm >
            )}
        </LayoutBaseDePagina>
    );
};