import React, { useEffect, useState } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { useField } from '@unform/core';

type TVTextFieldProps = TextFieldProps & {
    name: string;
    isDate?: boolean;
    initialValue?: string;
};

export const VTextField: React.FC<TVTextFieldProps> = ({ name, isDate = false, initialValue = '', ...rest }) => {
    const { fieldName, registerField, defaultValue, error, clearError } = useField(name);
    const [value, setValue] = useState(initialValue || defaultValue || '');

    useEffect(() => {
        registerField({
            name: fieldName,
            getValue: () => value,
            setValue: (_, newValue) => setValue(newValue),
        });
    }, [registerField, fieldName, value, initialValue, defaultValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        setValue(inputValue);
        rest.onChange?.(e);
    };

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        const formattedValue = formatValue(inputValue);
        setValue(formattedValue);
        rest.onChange?.(e);
    };

    const formatValue = (inputValue: string) => {
        let formattedValue = inputValue.replace(/\D/g, '');

        if (formattedValue.length > 2 && formattedValue.length <= 4) {
            formattedValue = formattedValue.replace(/(\d{2})(\d)/, '$1/$2');
        } else if (formattedValue.length > 4) {
            formattedValue = formattedValue.replace(/(\d{2})(\d{2})(\d)/, '$1/$2/$3');
        }

        return formattedValue;
    };

    return (
        <TextField
            {...rest}
            error={!!error}
            helperText={error}
            value={value}
            onChange={isDate ? handleDateChange : handleChange}
            onKeyDown={(e) => { error && clearError(); rest.onKeyDown?.(e); }}
        />
    );
};
