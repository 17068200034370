import { Box } from "@mui/system";
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import {
    Avatar,
    Divider,
    Drawer,
    Icon,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";

import avatar from '../../../assests/default-avatar.png'
import { useAppThemeContext, useDrawerContext, useAuthContext, useSelectedEstablishmentForListProductContext } from "../../contexts";

interface IAppSideBarProps {
    children: React.ReactNode
}

interface IListItemLinkProps {
    to: string;
    icon: string;
    label: string;
    onClick: (() => void) | undefined;
}

const ListItemLink: React.FC<IListItemLinkProps> = ({ to, icon, label, onClick }) => {

    const navigate = useNavigate();

    const { setSelectedEstablishment } = useSelectedEstablishmentForListProductContext();

    const resolvedPath = useResolvedPath(to);
    const match = useMatch({ path: resolvedPath.pathname, end: false });

    const handleClick = () => {
        if (resolvedPath.pathname === "/product") setSelectedEstablishment();
        navigate(to);
        onClick?.();
    };

    return (
        <ListItemButton selected={!!match} onClick={handleClick}>
            <ListItemIcon>
                <Icon>{icon}</Icon>
            </ListItemIcon>
            <ListItemText primary={label} />
        </ListItemButton>
    );
};

export const SideBar: React.FC<IAppSideBarProps> = ({ children }) => {
    const establishmentName = localStorage.getItem('LOGGED_ESTABLISHMENT_NAME');

    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    const { isDrawerOpen, drawerOptions, toggleDrawerOpen } = useDrawerContext();
    const { toggleTheme } = useAppThemeContext();
    const { logout } = useAuthContext();

    return (
        <>
            <Drawer open={isDrawerOpen} variant={smDown ? 'temporary' : 'permanent'} onClose={toggleDrawerOpen}>
                <Box width={theme.spacing(28)} height="{theme.spacing(20)}" display="flex" flexDirection="column" >
                    <Box width='100%' height={theme.spacing(20)} display="flex" alignItems="center" justifyContent='center'>
                        <Avatar src={avatar} sx={{ height: theme.spacing(12), width: theme.spacing(12), borderRadius: theme.spacing(8) }} />
                    </Box>
                </Box>
                <Divider />
                <Box flex={1}>
                    <Box >
                        <Typography align='center' whiteSpace="nowrap" textOverflow="ellipsis" >
                            <strong>{establishmentName ? establishmentName : ""}</strong>
                        </Typography>

                    </Box>
                    <List component="nav">
                        {drawerOptions.map(drawerOption => (
                            <ListItemLink
                                to={drawerOption.path}
                                key={drawerOption.path}
                                icon={drawerOption.icon}
                                label={drawerOption.label}
                                onClick={smDown ? toggleDrawerOpen : undefined}
                            />
                        ))}
                    </List>
                </Box>
                <Box>
                    <List component="nav">
                        <ListItemButton onClick={toggleTheme}>
                            <ListItemIcon>
                                <Icon>dark_mode</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Alternar tema" />
                        </ListItemButton>
                        <ListItemButton onClick={logout}>
                            <ListItemIcon>
                                <Icon>logout</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Sair" />
                        </ListItemButton>
                    </List>
                </Box>
            </Drawer>
            <Box height="100vh" marginLeft={smDown ? 0 : theme.spacing(28)}>
                {children}
            </Box>
        </>
    );
}