import { Api } from '../api/axios-config';
import { Environment } from '../../environments';
import { IPoints, IUpdatePoints } from '../../protocolos';

type TPointsDataCount = {
    data: IPoints[];
    totalCount: number;
}


const listAllByEstablishment = async (establishmentId: string, sort: string, page: number, order: string, like?: string): Promise<TPointsDataCount | Error> => {
    try {
        const url = `/points/establisment/${establishmentId}?like=${like ? like : ""}&limit=${Environment.LIMIT}&skip=${Environment.SKIP}&page=${page}&sort=${sort}&order=${order}`;
        const { data } = await Api.get(url, {});
        if (data) {
            return {
                data: data.data,
                totalCount: data.count
            };
        }
        return new Error('Erro ao listar os registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
    }
};

const getById = async (id: string): Promise<IPoints | Error> => {
    try {
        const { data } = await Api.get(`/points/${id}`, {});

        if (data) {
            return data;
        }

        return new Error('Erro ao consultar o registro.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
    }
};

const create = async (dados: Omit<IPoints, 'id'>): Promise<IPoints | Error> => {
    try {
        const { data } = await Api.post<IPoints>('/points', dados, {});

        if (data) {
            return data;
        }

        return new Error('Erro ao criar o registro.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
    }
};

const update = async (id: string, dados: IUpdatePoints): Promise<void | Error> => {
    try {
        await Api.put(`/points/${id}`, dados, {});
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
    }
};

const deleteById = async (id: string, userId: string): Promise<void | Error> => {
    try {
        await Api.delete(`/points/${id}/${userId}`, {});
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
    }
};

export const PointsService = {
    listAllByEstablishment,
    deleteById,
    getById,
    update,
    create
};