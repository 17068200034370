// AddProductsWithWinOnTime.tsx
import * as yup from 'yup';
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Toolbar } from "../../shared/components";
import { LayoutBaseDePagina } from "../../shared/layouts";
import {
    Box,
    Grid,
    Icon,
    IconButton,
    LinearProgress,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import { Environment } from "../../shared/environments";
import { IWinOnTimeOnProducts } from "../../shared/protocolos";
import { useDebounce } from "../../shared/hooks";
import { IVFormErrors, VForm, VTextField, useVForm } from "../../shared/forms";
import { AutoCompleteProductsByEstabliment, } from "./components/AutoCompleteProductsByEstablishment";
import { AutoCompleteTypeOfDiscount } from "./components/AutoCompleteTypeOfDiscount";
import { useAuthContext } from "../../shared/contexts";
import { WinOnTimeOnProductsService } from '../../shared/services';
import { IUpsertWinOnTimeOnProductsProtocol } from '../../shared/protocolos/win-on-time/upsertWinOnTimeOnProductsProtocol';
import { formatFoBrMoney, formatInputNumber } from 'shared/utils/formatNumber';

type Idata = {
    value: number,
    typeOfDiscount: string,
    discount: number,
    valueSale: number,
    productId: string,
    winOnTimeId: string,
    createdBy: string
}

export const AddProductsWithWinOnTime: React.FC = () => {

    const formValidationSchema: yup.Schema<Omit<IWinOnTimeOnProducts, 'product'>> = yup.object().shape({
        value: yup.number().required(),
        typeOfDiscount: yup.string().required(),
        discount: yup.number().required(),
        valueSale: yup.number().required(),
        productId: yup.string().required(),
        winOnTimeId: yup.string().required(),
        createdBy: yup.string().required(),
    });

    const establishmentId = localStorage.getItem('LOGGED_ESTABLISHMENT_ID');
    const { userLogged } = useAuthContext();
    const { formRef, save } = useVForm();
    const { id = '' } = useParams<'id'>();

    const navigate = useNavigate();
    const { debounce } = useDebounce();

    const [searchParams, setSearchParams] = useSearchParams();
    const [totalCount, setTotalCount] = useState(0)
    const [isProduct, setIsProduct] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [rows, setRows] = useState<IWinOnTimeOnProducts[]>([])

    const busca = useMemo(() => {
        return searchParams.get('busca') || '';
    }, [searchParams]);

    const pagina = useMemo(() => {
        return Number(searchParams.get('pagina') || "1");
    }, [searchParams]);

    useEffect(() => {
        setIsLoading(true);
        debounce(async () => {
            handleTableList();
        });
    }, [busca, pagina]);

    const handleTableList = async () => {
        await WinOnTimeOnProductsService.listAllWinOnTimeOnProductsByWinOnTimeId("createdAt", pagina, "asc", id, busca)
            .then((result) => {
                setIsLoading(false);
                if (result instanceof Error) {
                    alert(result.message);
                } else {
                    setTotalCount(result.totalCount);
                    setRows(result.data);
                }
            });
    }

    const upsertRows = (data: Idata): IUpsertWinOnTimeOnProductsProtocol => {

        const arrayReturn: [Idata] = [{
            value: data.value,
            typeOfDiscount: data.typeOfDiscount,
            discount: data.discount,
            valueSale: data.valueSale,
            productId: data.productId,
            winOnTimeId: data.winOnTimeId,
            createdBy: data.createdBy
        }];

        for (const row of rows) {
            if (data.productId !== row.productId) {
                const obj = {
                    value: row.value,
                    typeOfDiscount: row.typeOfDiscount,
                    discount: row.discount,
                    valueSale: row.valueSale,
                    productId: row.productId,
                    winOnTimeId: row.winOnTimeId,
                    createdBy: row.createdBy
                }
                arrayReturn.push(obj)
            }
        }

        return {
            upsertWinOnTimeOnProducts: arrayReturn
        }
    }

    const handleSave = (dados: IWinOnTimeOnProducts) => {
        dados.createdBy = userLogged.id;
        dados.winOnTimeId = id;
        dados.discount = formatInputNumber(dados.discount.toString());
        dados.valueSale = calculationValueSale(dados.value, dados.discount, dados.typeOfDiscount);
        formValidationSchema
            .validate(dados, { abortEarly: false })
            .then((dadosValidados) => {
                setIsLoading(true);
                WinOnTimeOnProductsService
                    .upsertWinOnTimeOnProducts(upsertRows(dadosValidados))
                    .then((result) => {
                        setIsLoading(false);
                        if (result instanceof Error) {
                            alert(result.message);
                        } else {
                            handleTableList();
                            formRef.current?.setData({
                                value: "",
                                typeOfDiscount: "",
                                discount: "",
                                valueSale: ""
                            });
                        }
                    });
            })
            .catch((errors: yup.ValidationError) => {
                const validationErrors: IVFormErrors = {};
                errors.inner.forEach(error => {
                    if (!error.path) return;
                    validationErrors[error.path] = error.message;
                });
                formRef.current?.setErrors(validationErrors);
            });
    };

    const calculationValueSale = (value: number, discount: number, typeOfDiscount: string): number => {
        let valueSale = 0;

        if (typeOfDiscount === "Porcentagem") {
            valueSale = value - ((value * discount) / 100);
        } else {
            valueSale = value - discount;
        }

        return valueSale;
    };

    const handleDelete = (id: string) => {
        // Aqui você pode implementar a lógica para deletar um produto
    };

    return (
        <LayoutBaseDePagina
            barraDeFerramentas={
                <Toolbar
                    mostrarInputBusca
                    textoDaBusca={busca}
                    textoBotaoNovo='Adicionar'
                    mostrarBotaoVoltar={true}
                    aoClicarEmNovo={save}
                    aoClicarEmVoltar={() => { navigate(`/winOnTime/`); }}
                    aoMudarTextoDeBusca={texto => setSearchParams({ busca: texto, pagina: '1' }, { replace: true })}
                />
            }
        >
            <Box display="flex" flexDirection="column">
                <VForm ref={formRef} onSubmit={handleSave}>
                    <Box margin={1} component={Paper} variant="outlined">
                        <Grid container direction="column" padding={2} spacing={2}>
                            <Grid item>
                                <Typography variant='h6'>Adicionar Produto</Typography>
                            </Grid>
                            <Box display="flex" flexDirection="row" justifyItems="center" alignItems="center">
                                <Grid container item padding={2} spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={8} xl={10}>
                                        <AutoCompleteProductsByEstabliment
                                            establismentId={establishmentId as any}
                                            onProductSelected={(product) => {
                                                if (product === null) {
                                                    formRef.current?.setFieldValue('value', "");
                                                } else {
                                                    formRef.current?.setFieldValue('value', String(product.value));
                                                    product.type_of_sale === "PRODUTO" ? setIsProduct(true) : setIsProduct(false)
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item padding={2} spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={8} xl={10}>
                                        <VTextField
                                            fullWidth
                                            name='value'
                                            label='Valor'
                                            disabled={true}
                                            initialValue={''}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item padding={2} spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={8} xl={10}>
                                        <AutoCompleteTypeOfDiscount isProduct={isProduct} />
                                    </Grid>
                                </Grid>
                                <Grid container item padding={2} spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={8} xl={10}>
                                        <VTextField
                                            fullWidth
                                            name='discount'
                                            label='Desconto'
                                            disabled={isLoading}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>
                </VForm>
            </Box>
            <TableContainer component={Paper} variant='outlined' sx={{ m: 1, width: 'auto' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {/* <TableCell width={100}>Ações</TableCell> */}
                            <TableCell>Produto</TableCell>
                            <TableCell>Valor</TableCell>
                            <TableCell>Tipo de Desconto</TableCell>
                            <TableCell>Desconto</TableCell>
                            <TableCell>Valor de Venda</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => {
                            return (
                                <TableRow key={row.id}>
                                    {/* <TableCell >
                                        <IconButton onClick={() => handleDelete(row.id as string)}>
                                            <Icon>
                                                delete
                                            </Icon>
                                        </IconButton>
                                    </TableCell> */}
                                    <TableCell>
                                        <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" variant='inherit'>
                                            {row.product.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                            {formatFoBrMoney(row.value)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                            {row.typeOfDiscount}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                            {formatFoBrMoney(row.discount)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                            {formatFoBrMoney(row.valueSale)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                    {totalCount === 0 && !isLoading &&
                        (
                            <caption>{Environment.LISTAGEM_VAZIA}</caption>
                        )
                    }
                    <TableFooter>
                        {isLoading && (
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <LinearProgress variant='indeterminate' />
                                </TableCell>
                            </TableRow>
                        )}
                        {(totalCount > 0 && totalCount > Environment.LIMIT) && (
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <Pagination
                                        page={pagina}
                                        count={Math.ceil(totalCount / Environment.LIMIT)}
                                        onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
                                    />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableFooter>
                </Table>
            </TableContainer>
        </LayoutBaseDePagina>
    );
};

