import { Api } from '../api/axios-config';
import { Environment } from '../../environments';
import { IPointsLog, IUpdatePointsLog } from '../../protocolos';

type TPointsLogDataCount = {
    data: IPointsLog[];
    totalCount: number;
}

const listAll = async (sort: string, page: number, order: string, like?: string): Promise<TPointsLogDataCount | Error> => {
    try {
        const url = `/pointsLog?limit=${Environment.LIMIT}&skip=${Environment.SKIP}&sort=${sort}&like=${like ? like : ""}&page=${page}&order=${order}`;
        const { data } = await Api.get(url, {});
        if (data) {
            return {
                data: data.data,
                totalCount: data.count
            };
        }
        return new Error('Erro ao listar os registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
    }
};

const listAllByPointsIdAndUserId = async (pointsId: string, userId: string, sort: string, page: number, order: string, like?: string): Promise<TPointsLogDataCount | Error> => {
    try {
        const url = `/pointsLog/points/${pointsId}/user/${userId}?like=${like ? like : ""}&limit=${Environment.LIMIT}&skip=${Environment.SKIP}&page=${page}&sort=${sort}&order=${order}`;
        const { data } = await Api.get(url, {});
        if (data) {
            return {
                data: data.data,
                totalCount: data.count
            };
        }
        return new Error('Erro ao listar os registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
    }
};

const listAllByEstablishmentId = async (establishmentId: string, sort: string, page: number, order: string, like?: string): Promise<TPointsLogDataCount | Error> => {
    try {
        const url = `/pointsLog/establishment/${establishmentId}/?like=${like ? like : ""}&limit=${Environment.LIMIT}&skip=${Environment.SKIP}&page=${page}&sort=${sort}&order=${order}`;
        const { data } = await Api.get(url, {});
        if (data) {
            return {
                data: data.data,
                totalCount: data.count
            };
        }
        return new Error('Erro ao listar os registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
    }
};

const getById = async (id: string): Promise<IPointsLog | Error> => {
    try {
        const { data } = await Api.get(`/pointsLog/:PointsLogId${id}`, {});

        if (data) {
            return data;
        }

        return new Error('Erro ao consultar o registro.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
    }
};

const create = async (dados: Omit<IPointsLog, 'id'>): Promise<IPointsLog | Error> => {
    try {
        const { data } = await Api.post<IPointsLog>('/pointsLog', dados, {});

        if (data) {
            return data;
        }

        return new Error('Erro ao criar o registro.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
    }
};

const update = async (id: string, dados: IUpdatePointsLog): Promise<void | Error> => {
    try {
        await Api.put(`/pointsLog/${id}`, dados, {});
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
    }
};

const deleteById = async (id: string, userId: string): Promise<void | Error> => {
    try {
        await Api.delete(`/pointsLog/${id}/${userId}`, {});
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
    }
};

export const PointsLogService = {
    listAll,
    listAllByPointsIdAndUserId,
    listAllByEstablishmentId,
    deleteById,
    getById,
    update,
    create
};