// AutoCompleteProductsByEstablishment.tsx
import { useField } from '@unform/core';
import { useEffect, useState, forwardRef, useRef } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';

import { useDebounce } from '../../../shared/hooks';
import { ProductService } from '../../../shared/services';

export interface TProduct {
    id: string | undefined;
    name: string;
    value: number;
    type_of_sale: string;
}

export interface IAutoCompleteProductsByEstablimentProps {
    isExternalLoading?: boolean;
    establismentId: string;
    onProductSelected: (product: TProduct | null) => void;
}

export const AutoCompleteProductsByEstabliment = forwardRef<any, IAutoCompleteProductsByEstablimentProps>((props, ref) => {
    const { isExternalLoading = false, establismentId, onProductSelected } = props;
    const autoCompleteRef = useRef<any>(null);
    const { fieldName, registerField, defaultValue, error } = useField('productId');
    const [selectedId, setSelectedId] = useState<string | undefined>(defaultValue);
    const [opcoes, setOpcoes] = useState<TProduct[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [busca, setBusca] = useState('');
    const { debounce } = useDebounce();

    useEffect(() => {
        registerField({
            name: fieldName,
            getValue: () => selectedId,
            setValue: (_, newSelectedId) => setSelectedId(newSelectedId),
        });
    }, [registerField, fieldName, selectedId]);

    useEffect(() => {
        setIsLoading(true);
        debounce(() => {
            ProductService.listAllByEstablishment("id", 1, "asc", establismentId, "")
                .then((result) => {
                    setIsLoading(false);
                    if (result instanceof Error) {
                    } else {
                        setOpcoes(result.data.map(product => ({ id: product.id, name: product.name, value: product.value, type_of_sale: product.typeOfSale })));
                    }
                });
        });
    }, [busca]);

    const handleProductSelected = (product: TProduct | null) => {
        onProductSelected(product);
        if (product) {
            setSelectedId(product.id);
        } else {
            setSelectedId('');
        }
        setBusca('');
    };

    return (
        <>
            <Autocomplete
                ref={autoCompleteRef}
                openText='Abrir'
                closeText='Fechar'
                noOptionsText='Sem opções'
                loadingText='Carregando...'
                clearText='Limpar'
                disablePortal
                options={opcoes}
                getOptionLabel={(option) => option.name ?? ''}
                loading={isLoading}
                disabled={isExternalLoading}
                onInputChange={(_, newValue) => setBusca(newValue)}
                onChange={(_, newValue) => handleProductSelected(newValue)}
                popupIcon={(isExternalLoading || isLoading) ? <CircularProgress size={28} /> : undefined}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Produtos"
                        error={!!error}
                        helperText={error}
                    />
                )}
            />
            <input type="hidden" name={fieldName} value={selectedId} />
        </>
    );
});
