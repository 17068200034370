import * as yup from 'yup';
import { MuiFileInput } from 'mui-file-input';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, LinearProgress, Paper, Typography } from '@mui/material';

import { DetailTool } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { IBenefitRedemption } from '../../shared/protocolos';
import { BenefitRedemptionService } from '../../shared/services';
import { useAuthContext } from '../../shared/contexts/AuthContext';
import { AutoCompleteStatus } from './componets/AutoCompleteStatus';
import { removeObjectToBucket, sendImagemToS3 } from '../../shared/services';
import { VTextField, VForm, useVForm, IVFormErrors } from '../../shared/forms';

const formValidationSchema: yup.Schema<IBenefitRedemption> = yup.object().shape({
    description: yup.string().required(),
    costPoints: yup.number().required().integer(),
    status: yup.string().required(),
    award: yup.string().required(),
    amount: yup.number().required().integer(),
    photograph: yup.string().required(),
    photographPath: yup.string().required(),
    establishmentId: yup.string().required(),
    createdBy: yup.string().required(),
});

export const UpsertAwards: React.FC = () => {

    const userId = localStorage.getItem('LOGGED_USER_ID');
    const establishmentId = localStorage.getItem('LOGGED_ESTABLISHMENT_ID');
    const establishmentName = localStorage.getItem('LOGGED_ESTABLISHMENT_NAME');

    const [file, setFile] = useState<File | null>(null)
    const [fileName, setFileName] = useState<string>("")
    const [filePath, setFilePath] = useState<string>("")
    const [isLoading, setIsLoading] = useState(false);

    const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();
    const { id = 'nova' } = useParams<'id'>();
    const { userLogged } = useAuthContext();
    const navigate = useNavigate();


    useEffect(() => {
        if (id !== 'nova') {
            setIsLoading(true);
            BenefitRedemptionService.getById(id)
                .then((result) => {
                    setIsLoading(false);
                    if (result instanceof Error) {
                        alert(result.message);
                        navigate('/awards');
                    } else {
                        setFileName(result.photograph);
                        formRef.current?.setData(result);
                    }
                });
        } else {
            formRef.current?.setData({
                description: "",
                costPoints: "",
                status: "",
                award: "",
                photograph: "",
                amount: "",
                photographPath: ""
            });
        }
    }, [id]);


    const handleChange = (newValue: File | null, fileName: string | undefined) => {
        setFile(newValue);
        if (!fileName) {
            setFileName("");
            setFilePath("");
        } else {
            setFileName(fileName);
            setFilePath(`${establishmentName}/regates/premios/${fileName}`);
        }
    }

    const handleSave = async (dados: IBenefitRedemption) => {
        dados.createdBy = userLogged.id;
        dados.establishmentId = establishmentId ? establishmentId : "";
        dados.photograph = fileName;
        dados.photographPath = filePath;
        if (!file) {
            alert("nenhum arquivo selecionado");
            return
        }
        formValidationSchema.
            validate(dados, { abortEarly: false })
            .then(async (dadosValidados) => {
                setIsLoading(true);
                if (id === 'nova') {
                    try {
                        await sendImagemToS3(file, establishmentName as string);
                        BenefitRedemptionService
                            .create(dadosValidados)
                            .then((result) => {
                                setIsLoading(false);
                                if (result instanceof Error) {
                                    alert(result.message);
                                } else {
                                    if (isSaveAndClose()) {
                                        navigate('/awards');
                                    } else {
                                        navigate(`/awards/detalhe/${result.id}`);
                                    }
                                }
                            });
                    } catch (error) {
                        console.error('Erro ao enviar imagem para o S3:', error);
                    }
                } else {
                    await sendImagemToS3(file, establishmentName as string);
                    BenefitRedemptionService
                        .update(id, dadosValidados)
                        .then((result) => {
                            setIsLoading(false);
                            if (result instanceof Error) {
                                alert(result.message);
                            } else {
                                if (isSaveAndClose()) {
                                    navigate('/awards');
                                }
                            }
                        });
                }
            })
            .catch((errors: yup.ValidationError) => {
                const validationErrors: IVFormErrors = {};
                errors.inner.forEach(error => {
                    if (!error.path) return;
                    validationErrors[error.path] = error.message;
                });
                formRef.current?.setErrors(validationErrors);
            });
    };

    const handleDelete = async (id: string) => {
        if (window.confirm('Realmente deseja apagar?')) {
            await removeObjectToBucket(fileName);
            await BenefitRedemptionService.deleteById(id, userId as string)
                .then(result => {
                    if (result instanceof Error) {
                        alert(result.message);
                    } else {
                        alert('Registro apagado com sucesso!');
                        navigate("/awards")
                    }
                });
        }
    };

    return (
        <LayoutBaseDePagina
            barraDeFerramentas={
                <DetailTool
                    textoBotaoNovo='Nova'
                    mostrarBotaoSalvarEFechar
                    mostrarBotaoNovo={id !== 'nova'}
                    mostrarBotaoApagar={id !== 'nova'}
                    aoClicarEmSalvar={save}
                    aoClicarEmSalvarEFechar={saveAndClose}
                    aoClicarEmApagar={() => handleDelete(id)}
                    aoClicarEmVoltar={() => navigate('/awards')}
                    aoClicarEmNovo={() => navigate('/awards/detalhe/nova')}
                />
            }
        >
            {id === "nova" && (
                <VForm ref={formRef} onSubmit={handleSave}>
                    <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
                        <Grid container direction="column" padding={2} spacing={2}>
                            {isLoading && (
                                <Grid item>
                                    <LinearProgress variant='indeterminate' />
                                </Grid>
                            )}
                            <Grid item>
                                <Typography variant='h6'>Adicionar Resgate</Typography>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='description'
                                        label='Descrição'
                                        disabled={isLoading}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='costPoints'
                                        label='Pontos para resgate'
                                        disabled={isLoading}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <AutoCompleteStatus />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='award'
                                        label='Prêmio'
                                        disabled={isLoading}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='amount'
                                        label='Quantidade'
                                        disabled={isLoading}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <MuiFileInput
                                        name='photograph'
                                        placeholder='Foto'
                                        value={file}
                                        onChange={(newFile) => {
                                            handleChange(newFile, newFile?.name)
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </VForm >
            )}
            {id !== "nova" && (
                <VForm ref={formRef} onSubmit={handleSave}>
                    <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
                        <Grid container direction="column" padding={2} spacing={2}>
                            {isLoading && (
                                <Grid item>
                                    <LinearProgress variant='indeterminate' />
                                </Grid>
                            )}
                            <Grid item>
                                <Typography variant='h6'>Geral</Typography>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='description'
                                        label='Descrição'
                                        disabled={isLoading}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='costPoints'
                                        label='Pontos para resgate'
                                        disabled={isLoading}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <AutoCompleteStatus />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='award'
                                        label='Prêmio'
                                        disabled={isLoading}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <VTextField
                                        fullWidth
                                        name='amount'
                                        label='Quantidade'
                                        disabled={isLoading}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                                    <MuiFileInput
                                        name='photograph'
                                        placeholder='Foto'
                                        value={file}
                                        onChange={(newFile) => {
                                            handleChange(newFile, newFile?.name)
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </VForm >
            )}
        </LayoutBaseDePagina>
    );
};