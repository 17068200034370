// AutoCompleteProductsByEstablishment.tsx
import { useField } from '@unform/core';
import { useEffect, useState, forwardRef, useRef } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';

import { useDebounce } from '../../../shared/hooks';
import { BenefitRedemptionService } from '../../../shared/services';

export interface TAward {
    id: string | undefined;
    award: string;
    costPoints: number;
    amount: number;
}

export interface IAutoCompleteAwardProps {
    isExternalLoading?: boolean;
    establismentId: string;
    onAwardSelected: (product: TAward | null) => void;
}

export const AutoCompleteAward = forwardRef<any, IAutoCompleteAwardProps>((props) => {

    const { isExternalLoading = false, establismentId, onAwardSelected } = props;
    const { fieldName, registerField, defaultValue, error } = useField('benefitRedemptionId');

    const { debounce } = useDebounce();
    const [busca, setBusca] = useState('');
    const autoCompleteRef = useRef<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [opcoes, setOpcoes] = useState<TAward[]>([]);
    const [selectedId, setSelectedId] = useState<string | undefined>(defaultValue);

    useEffect(() => {
        registerField({
            name: fieldName,
            getValue: () => selectedId,
            setValue: (_, newSelectedId) => setSelectedId(newSelectedId),
        });
    }, [registerField, fieldName, selectedId]);

    useEffect(() => {
        setIsLoading(true);
        debounce(() => {
            BenefitRedemptionService.findAllAwardByEstablishmentId(establismentId)
                .then((result) => {
                    setIsLoading(false);
                    if (result instanceof Error) {
                    } else {
                        setOpcoes(result.data.map(item => ({ id: item.id, award: item.award, costPoints: item.costPoints, amount: item.amount })));
                    }
                });
        });
    }, [busca]);

    const handleAwardSelected = (award: TAward | null) => {
        onAwardSelected(award);
        if (award) {
            setSelectedId(award.id);
        } else {
            setSelectedId('');
        }
        setBusca('');
    };

    return (
        <>
            <Autocomplete
                ref={autoCompleteRef}
                openText='Abrir'
                closeText='Fechar'
                noOptionsText='Sem opções'
                loadingText='Carregando...'
                clearText='Limpar'
                disablePortal
                options={opcoes}
                getOptionLabel={(option) => option.award ?? ''}
                loading={isLoading}
                disabled={isExternalLoading}
                onInputChange={(_, newValue) => setBusca(newValue)}
                onChange={(_, newValue) => handleAwardSelected(newValue)}
                popupIcon={(isExternalLoading || isLoading) ? <CircularProgress size={28} /> : undefined}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Prêmios"
                        error={!!error}
                        helperText={error}
                    />
                )}
            />
            <input type="hidden" name={fieldName} value={selectedId} />
        </>
    );
});
