import { IEstablishment, IUpdateEstablishment } from '../../protocolos';
import { Environment } from '../../environments';
import { Api } from '../api/axios-config';

type TEstablishmentDataCount = {
    data: IEstablishment[];
    totalCount: number;
}

const listAll = async (sort: string, page: number, order: string, like?: string): Promise<TEstablishmentDataCount | Error> => {
    try {
        const url = `/establishment?limit=${Environment.LIMIT}&skip=${Environment.SKIP}&sort=${sort}&like=${like ? like : ""}&page=${page}&order=${order}`;
        const { data } = await Api.get(url, {});
        if (data) {
            return {
                data: data.data,
                totalCount: data.count
            };
        }
        return new Error('Erro ao listar os registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
    }
};

const getById = async (id: string): Promise<IEstablishment | Error> => {
    try {
        const { data } = await Api.get(`/establishment/${id}`, {});

        if (data) {
            return data;
        }

        return new Error('Erro ao consultar o registro.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
    }
};

const findByUserId = async (userId: string): Promise<IEstablishment[] | Error> => {
    try {
        const { data } = await Api.get(`/establishment/userId/${userId}`, {});

        if (data) {
            return data;
        }

        return new Error('Erro ao consultar o registro.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
    }
};

const create = async (dados: Omit<IEstablishment, 'id'>): Promise<IEstablishment | Error> => {
    try {
        const { data } = await Api.post<IEstablishment>('/establishment', dados, {});

        if (data) {
            return data;
        }

        return new Error('Erro ao criar o registro.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
    }
};

const update = async (id: string, dados: IUpdateEstablishment): Promise<void | Error> => {
    try {
        await Api.put(`/establishment/${id}`, dados, {});
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
    }
};

const deleteById = async (id: string, userId: string): Promise<void | Error> => {
    try {
        await Api.delete(`/establishment/${id}?userId=${userId}`, {});
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
    }
};

export const EstablishmentService = {
    listAll,
    deleteById,
    getById,
    findByUserId,
    update,
    create
};