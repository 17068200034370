import { Box, Button, Icon, Paper, TextField, useTheme, Typography, Divider, Skeleton } from '@mui/material';

import { Environment } from '../../environments';

interface IToolbarProps {
  textoDaBusca?: string;
  mostrarInputBusca?: boolean;
  aoMudarTextoDeBusca?: (novoTexto: string) => void;
  textoBotaoNovo?: string;
  mostrarBotaoNovo?: boolean;
  aoClicarEmNovo?: () => void;
  mostrarBotaoVoltar?: boolean;
  mostrarBotaoVoltarCarregando?: boolean;
  aoClicarEmVoltar?: () => void;


}
export const Toolbar: React.FC<IToolbarProps> = ({

  textoDaBusca = '',
  aoMudarTextoDeBusca,
  mostrarInputBusca = false,
  mostrarBotaoVoltarCarregando = false,
  mostrarBotaoVoltar = false,
  aoClicarEmVoltar,
  aoClicarEmNovo,
  textoBotaoNovo = 'Novo',
  mostrarBotaoNovo = true,
}) => {
  const theme = useTheme();
  return (
    <Box
      gap={1}
      marginX={1}
      padding={1}
      paddingX={2}
      display="flex"
      alignItems="center"
      height={theme.spacing(5)}
      component={Paper}
    >
      {mostrarInputBusca && (
        <TextField
          size="small"
          value={textoDaBusca}
          placeholder={Environment.INPUT_DE_BUSCA}
          onChange={(e) => aoMudarTextoDeBusca?.(e.target.value)}
        />
      )}



      <Box flex={1} display="flex" justifyContent="end">

        {mostrarBotaoNovo && (
          <Button
            color='primary'
            disableElevation
            variant='contained'
            onClick={aoClicarEmNovo}
            endIcon={<Icon>add</Icon>}
          >{textoBotaoNovo}</Button>
        )}

        {
          (mostrarBotaoVoltar) && (
            <Divider variant='middle' orientation='vertical' />
          )
        }

        {(mostrarBotaoVoltar && !mostrarBotaoVoltarCarregando) && (
          <Button
            color='primary'
            disableElevation
            variant='outlined'
            onClick={aoClicarEmVoltar}
            startIcon={<Icon>arrow_back</Icon>}
          >
            <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
              Voltar
            </Typography>
          </Button>
        )}

        {mostrarBotaoVoltarCarregando && (
          <Skeleton width={110} height={60} />
        )}
      </Box>
    </Box>
  );
};