import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';

import { useField } from '@unform/core';

type TAutoCompleteOption = {
    showInApp: string;
}

interface IAutoCompleteShowInAppProps {
    isExternalLoading?: boolean;
}

export const AutoCompleteShowInApp: React.FC<IAutoCompleteShowInAppProps> = ({ isExternalLoading = false }) => {

    const { fieldName, registerField, defaultValue, error, clearError } = useField('showInApp');
    const [selectedShowInApp, setSelectedShowInApp] = useState<boolean | undefined>(defaultValue);
    const [isLoading, setIsLoading] = useState(true);
    const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);
    const [busca, setBusca] = useState('');

    useEffect(() => {
        registerField({
            name: fieldName,
            getValue: () => selectedShowInApp,
            setValue: (_, newSelectedShowInApp) => setSelectedShowInApp(newSelectedShowInApp),
        });
    }, [registerField, fieldName, selectedShowInApp]);

    useEffect(() => {
        setIsLoading(false)
        setOpcoes([
            { showInApp: "SIM" },
            { showInApp: "NÂO" }
        ])
    }, [busca]);

    const autoCompleteSelectedOption = useMemo(() => {
        if (!selectedShowInApp) return null;

        const selectedOption = opcoes.find(opcao => opcao.showInApp === "SIM" ? true : false);
        if (!selectedOption) return null;

        return selectedOption;
    }, [selectedShowInApp, opcoes]);


    return (
        <Autocomplete
            openText='Abrir'
            closeText='Fechar'
            noOptionsText='Sem opções'
            loadingText='Carregando...'
            clearText='Limpar'

            disablePortal

            options={opcoes}
            getOptionLabel={(option) => option.showInApp ?? option}
            loading={isLoading}
            disabled={isExternalLoading}
            value={autoCompleteSelectedOption}
            onInputChange={(_, newValue) => setBusca(newValue)}
            onChange={(_, newValue) => { setSelectedShowInApp(newValue?.showInApp === "SIM" ? true : false); setBusca(''); clearError(); }}
            popupIcon={(isExternalLoading || isLoading) ? <CircularProgress size={28} /> : undefined}
            renderInput={(params) => (
                <TextField
                    {...params}

                    label="Mostrar no App"
                    error={!!error}
                    helperText={error}
                />
            )}
        />
    );
};