import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableFooter,
    LinearProgress,
    Pagination,
    IconButton,
    Icon,
    Typography
} from '@mui/material'

import { useDebounce } from '../../shared/hooks';
import { Toolbar } from '../../shared/components';
import { IWinOnTime } from '../../shared/protocolos';
import { Environment } from '../../shared/environments';
import { WinOnTimeService } from '../../shared/services';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { formatDateToString } from 'shared/utils';

export const WinOnTime: React.FC = () => {

    const establishmentId = localStorage.getItem('LOGGED_ESTABLISHMENT_ID');
    const userId = localStorage.getItem('LOGGED_USER_ID');

    const [searchParams, setSearchParams] = useSearchParams();
    const { debounce } = useDebounce();
    const navigate = useNavigate();

    const [rows, setRows] = useState<IWinOnTime[]>([])
    const [totalCount, setTotalCount] = useState(0)
    const [isLoading, setIsLoading] = useState(true)

    const busca = useMemo(() => {
        return searchParams.get('busca') || '';
    }, [searchParams]);

    const pagina = useMemo(() => {
        return Number(searchParams.get('pagina') || "1");
    }, [searchParams]);

    useEffect(() => {
        setIsLoading(true);
        debounce(async () => {
            handleTableList();
        });
    }, [busca, pagina]);

    const handleTableList = async () => {
        await WinOnTimeService.listAll("id", pagina, "asc", establishmentId ? establishmentId : "", busca)
            .then((result) => {
                setIsLoading(false);

                if (result instanceof Error) {
                    alert(result.message);
                } else {
                    setTotalCount(result.totalCount);
                    setRows(result.data);
                }
            });
    }

    const handleDelete = async (id: string) => {
        if (window.confirm('Realmente deseja apagar?')) {
            await WinOnTimeService.deleteById(id, userId as string)
                .then(result => {
                    if (result instanceof Error) {
                        alert(result.message);
                    } else {
                        handleTableList();
                        alert('Registro apagado com sucesso!');
                    }
                });
        }
    };

    return (
        <LayoutBaseDePagina
            barraDeFerramentas={
                <Toolbar
                    mostrarInputBusca
                    textoDaBusca={busca}
                    textoBotaoNovo='Nova'
                    aoClicarEmNovo={() => { navigate('/winOnTime/detalhe/nova') }}
                    aoMudarTextoDeBusca={texto => setSearchParams({ busca: texto, pagina: '1' }, { replace: true })}
                />
            }
        >
            <TableContainer component={Paper} variant='outlined' sx={{ m: 1, width: 'auto' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width={100}>Ações</TableCell>
                            <TableCell>Descrição</TableCell>
                            <TableCell>Mostrar no APP</TableCell>
                            <TableCell>Tipo</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Promotor</TableCell>
                            <TableCell>Data de criação</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => (
                            // whiteSpace="nowrap" textOverflow="ellipsis"
                            <TableRow key={row.id} >
                                <TableCell >
                                    <IconButton onClick={() => handleDelete(row.id as string)}>
                                        <Icon>
                                            delete
                                        </Icon>
                                    </IconButton>
                                    <IconButton onClick={() => navigate(`/winOnTime/detalhe/${row.id}`)}>
                                        <Icon>
                                            edit
                                        </Icon>
                                    </IconButton>
                                    <IconButton onClick={() => navigate(`/winOnTime/qrcode/${row.id}`)}>
                                        <Icon>
                                            qr_code_scanner
                                        </Icon>
                                    </IconButton>
                                    <IconButton onClick={() => navigate(`/winOnTime/add/products/${row.id}`)}>
                                        <Icon>
                                            add_card
                                        </Icon>
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" >
                                        {row.description}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {row.showInApp ? "SIM" : "NÃO"}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {row.type}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {row.status}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {row.userPromoter}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {formatDateToString(row.createdAt?.toString() as any)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    {totalCount === 0 && !isLoading &&
                        (
                            <caption>{Environment.LISTAGEM_VAZIA}</caption>
                        )
                    }
                    <TableFooter>
                        {isLoading && (
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <LinearProgress variant='indeterminate' />
                                </TableCell>
                            </TableRow>
                        )}
                        {(totalCount > 0 && totalCount > Environment.LIMIT) && (
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <Pagination
                                        page={pagina}
                                        count={Math.ceil(totalCount / Environment.LIMIT)}
                                        onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
                                    />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableFooter>
                </Table>
            </TableContainer>
        </LayoutBaseDePagina>
    );

};