import { createTheme } from '@mui/material'
import { blue, cyan } from '@mui/material/colors';

export const DarktTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: blue[500],
            dark: blue[800],
            light: blue[300],
            contrastText: '#ffffff',
        },
        secondary: {
            main: cyan[500],
            dark: cyan[400],
            light: cyan[300],
            contrastText: '#ffffff',
        },
        background: {
            default: "#202124",
            paper: "#303134"
        },
    },
    typography: {
        allVariants: {
            color: 'white',
        }
    }
});