import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import {
    Box,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    Icon,
    Typography
} from '@mui/material';
import { IEstablishment } from 'shared/protocolos';
import { EstablishmentService } from 'shared/services';
import { Environment } from '../../shared/environments';
import { useDebounce } from '../../shared/hooks';
import { useAuthContext } from 'shared/contexts';

export const SelectEstablishment = () => {

    const { debounce } = useDebounce();
    const navigate = useNavigate();

    const { setEstablishment } = useAuthContext();
    const [rows, setRows] = useState<IEstablishment[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const { id = '' } = useParams<'id'>();

    const handleTableList = async () => {
        await EstablishmentService.findByUserId(id)
            .then((result) => {
                setIsLoading(false);

                if (result instanceof Error) {
                    alert(result.message);
                } else {
                    setTotalCount(result.length);
                    setRows(result);
                }
            });
    }

    const handleSubmit = async (establimentId: string, establismentName: string) => {
        await setEstablishment(establimentId, establismentName);
        navigate("/home");
    };

    useEffect(() => {
        setIsLoading(true);
        debounce(async () => {
            handleTableList();
        });
    }, []);

    return (
        <Box width='100vw' height='100vh' display='flex' alignItems='center' justifyContent='center'>

            <TableContainer component={Paper} variant='outlined' sx={{ m: 1, width: 'auto' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width={100}>Ações</TableCell>
                            <TableCell>Nome</TableCell>
                            <TableCell>CNPJ</TableCell>
                            <TableCell>Telefone</TableCell>
                            <TableCell>Nome Fantasia</TableCell>
                            <TableCell>Razao Social</TableCell>
                            <TableCell>Endereço</TableCell>
                            <TableCell>Bairro</TableCell>
                            <TableCell>CEP</TableCell>
                            <TableCell>Cidade</TableCell>
                            <TableCell>UF</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => (
                            <TableRow key={row.id}>
                                <TableCell>
                                    <IconButton onClick={() => { handleSubmit(row.id as string, row.name) }}>
                                        <Icon>
                                            done
                                        </Icon>
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" variant='inherit'>
                                        {row.name}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {row.cnpj}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {row.telefone}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {row.nomeFantasia}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {row.razaoSocial}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {row.endereco}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {row.bairro}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {row.cep}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {row.cidade}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        {row.uf}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    {totalCount === 0 && !isLoading &&
                        (
                            <caption>{Environment.LISTAGEM_VAZIA}</caption>
                        )
                    }
                </Table>
            </TableContainer>
        </Box>
    )
};
