import { createContext, useState, useContext } from "react";

interface ISelectedEstablishmentForListProductContextData {
    isSelected: boolean;
    setSelectedEstablishment: () => void
}
interface ISelectedEstablishmentForListProductProviderProps {
    children: React.ReactNode
}
const SelectedEstablishmentForListProductContext = createContext({} as ISelectedEstablishmentForListProductContextData);

export const useSelectedEstablishmentForListProductContext = () => {
    return useContext(SelectedEstablishmentForListProductContext);
}

export const SelectedEstablishmentForListProductProvider: React.FC<ISelectedEstablishmentForListProductProviderProps> = ({ children }) => {

    const [isSelectedEstablishment, setSelectedEstablishment] = useState(false);

    const handleChangeSelectedEstablishment = async () => {
        setSelectedEstablishment(!isSelectedEstablishment);
    }

    return (
        <SelectedEstablishmentForListProductContext.Provider value={{ isSelected: isSelectedEstablishment, setSelectedEstablishment: handleChangeSelectedEstablishment }}>
            {children}
        </SelectedEstablishmentForListProductContext.Provider>
    );
}